import React from 'react';
import { withRouter } from "react-router-dom";

import Image from './Image.js';
import FormInput from '../Form/Input';
import FormRadioStandard from '../Form/RadioStandard';

class Occhielli extends React.Component {
    constructor(props) {
        super(props);

        this.step = props.steps['occhielli'];

        this.state = {
            nextStep: 'sgocciolatoio'
        }

        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount() {
        this.props.updateStep(this.step.id, this.step.name, this.state.nextStep, this.props.match.params.typeId)
    }

    handleInputChange = event => {
        const target = event.target;
        let value = target.getAttribute('type') === 'checkbox' ? target.checked : target.value;
        let name = target.name;

        if (name.indexOf('[')>0) {
            let newName = name.replace(/\[(\w+)]/g, '')
            let key = /\[(.*?)\]/g.exec(name)[1]

            let newValue = this.props.stepsData[this.props.currentType][this.step.id][newName]
            newValue[key] = value

            name = newName
            value = newValue
        }

        this.props.updateData(this.props.currentType, this.step.id, name, value)
    }

    render() {
        if (this.props.stepsData[this.props.currentType]) {
            return (
                <div className="step step__occhielli">
                    <div className="row">
                        <div className="col-22 col-lg-20 col-xl-18 offset-1 offset-lg-2 offset-xl-3">
                            <div className="step__image">
                                <Image directory={this.props.currentStep.id} name="occhielli" />
                            </div>
                            <div className="step__form">
                                <div className="row">
                                    <div className="col-24 col-md-10 col-xl-8 offset-md-2 offset-xl-4">
                                        <FormInput type="number" name="numero" id="numero" value={this.props.stepsData[this.props.currentType].occhielli.numero} label="Numero Occhielli" handleChange={this.handleInputChange} />
                                    </div>
                                    <div className="col-24 col-md-10 col-xl-8">
                                        <div className="step__radio-label">
                                            Disposizione Occhielli
                                        </div>
                                        <div className="row">
                                            <div className="col-auto">
                                                <FormRadioStandard name="disposizione" id="disposizione1" value="Orizzontale" label="Orizzontale" image={true} currentStep={this.props.currentStep} handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].occhielli.disposizione === 'Orizzontale'} />
                                            </div>
                                            <div className="col-auto">
                                                <FormRadioStandard name="disposizione" id="disposizione2" value="Verticale" label="Verticale" image={true} currentStep={this.props.currentStep} handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].occhielli.disposizione === 'Verticale'} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-24 col-md-10 col-xl-8 offset-md-2 offset-xl-4">
                                        <FormInput type="number" name="quota" id="quota" value={this.props.stepsData[this.props.currentType].occhielli.quota} label="Quota Occhielli" handleChange={this.handleInputChange} />
                                    </div>
                                    <div className="col-24 col-md-10 col-xl-8">
                                        <FormInput type="number" name="interasse" id="interasse" value={this.props.stepsData[this.props.currentType].occhielli.interasse} label="Interasse Occhielli" handleChange={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return null
        }
    }
}

export const ValidateOcchielli = (stepsData, currentStepData, currentType) => {
    let isValid = true
    let currentStep = stepsData[currentType].occhielli

    if(!currentStep.numero || !currentStep.disposizione || !currentStep.quota || !currentStep.interasse) {
        isValid = false
    }

    return isValid;
}

export default withRouter(Occhielli)
