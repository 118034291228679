import React from 'react';
import { withRouter } from "react-router-dom";
import SimpleStorage from "react-simple-storage";

import Image from './Image.js';
import FormRadio from '../Form/Radio';

class Teli extends React.Component {
    constructor(props) {
        super(props);

        this.step = props.steps['teli'];

        this.state = {
            nextStep: 'teli_laterali'
        }

        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount() {
        this.props.updateStep(this.step.id, this.step.name, this.state.nextStep, this.props.match.params.typeId)
    }

    updateSubstep = (substep, steps) => {
        this.props.updateStep(this.step.id, this.step.name, this.props.currentStep.next, null, substep, steps);
    }

    handleInputChange = event => {
        const target = event.target;
        let value = target.getAttribute('type') === 'checkbox' ? target.checked : target.value;
        let name = target.name;

        if (name.indexOf('[')>0) {
            let newName = name.replace(/\[(\w+)]/g, '')
            let key = /\[(.*?)\]/g.exec(name)[1]

            let newValue = this.props.stepsData[this.props.currentType][this.step.id][newName]
            newValue[key] = value

            name = newName
            value = newValue
        }

        this.props.updateData(this.props.currentType, this.step.id, name, value)

        if (name==='teli') {
            this.updateNextStep(value)
        }
    }

    updateNextStep = (choice) => {
        let nextStep = 'teli_laterali'

        if (choice==='Telo tetto') {
            nextStep = 'tipologia_tetto'
        }

        this.setState({ 
            nextStep: nextStep
        }, () => {
            this.props.updateStep(this.step.id, this.step.name, this.state.nextStep, null, this.props.currentStep.substep, this.props.currentStep.subSteps);
        })
    }

    render() {
        return(
            <div>
                <SimpleStorage parent={this} prefix={"Configurator"} onParentStateHydrated={() => {
                    if (this.props.stepsData.tipologia_mezzo.tipo_mezzo==='Motrice + rimorchio') {
                        this.updateSubstep(0,2)
                    }
                    else {
                        this.updateSubstep(0,0)
                    }
                    this.updateNextStep(this.props.stepsData[this.props.currentType].teli.teli)
                }} />
                <ShowTeli type={this.props.stepsData.tipologia_mezzo.tipo_mezzo} currentStep={this.props.currentStep} currentType={this.props.currentType} stepsData={this.props.stepsData} handleInputChange={this.handleInputChange} />
            </div>
        )
    }
}

const ShowTeli = (props) => {
    if (!props.type) {
        return null
    }

    if (props.type!=='Motrice + rimorchio') {
        return (
            <OptionsTeli type={props.stepsData.tipologia_mezzo.tipo_mezzo} currentStep={props.currentStep} currentType={props.currentType} stepsData={props.stepsData} handleInputChange={props.handleInputChange} />
        )
    }
    else if(props.type==='Motrice + rimorchio') {
        if (props.currentStep.substep===0 || !props.currentStep.substep) {
            if (props.currentType==='motrice') {
                return (
                    <div className="step step__misure-mezzo step__misure-mezzo--motrice-rimorchio">
                        <div className="row">
                            <div className="col-22 col-lg-10 offset-1 offset-lg-7">
                                <div className="step__text">
                                    Procedi prima con la <strong>motrice</strong>, succesivamente ti chiederemo di inserire le misure del rimorchio
                                </div>
                            </div>
                            <div className="col-22 col-lg-20 col-xl-18 offset-1 offset-lg-2 offset-xl-3">
                                <div className="step__image">
                                    <Image directory={props.currentStep.id} name="motrice-rimorchio-1"/>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
            else {
                return (
                    <div className="step step__misure-mezzo step__misure-mezzo--motrice-rimorchio">
                        <div className="row">
                            <div className="col-22 col-lg-10 offset-1 offset-lg-7">
                                <div className="step__text">
                                    La motrice è completata, ora inserisci le misure del <strong>rimorchio</strong>
                                </div>
                            </div>
                            <div className="col-22 col-lg-20 col-xl-18 offset-1 offset-lg-2 offset-xl-3">
                                <div className="step__image">
                                    <Image directory={props.currentStep.id} name="motrice-rimorchio-2"/>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        }
        else if (props.currentStep.substep===1) {
            return (
                <OptionsTeli type={props.stepsData.tipologia_mezzo.tipo_mezzo} currentStep={props.currentStep} currentType={props.currentType} stepsData={props.stepsData} handleInputChange={props.handleInputChange} />
            )
        }
    }
    else {
        return null
    }
}

const OptionsTeli = (props) => {
    return (
        <div className="step step__teli">
            <div className="row">
                <div className="col-22 col-lg-6 offset-1">
                    <FormRadio name="teli" id="teli3" value="Telo completo" label="Telo completo" handleChange={props.handleInputChange} checked={props.stepsData[props.currentType].teli.teli === 'Telo completo'} />
                </div>
                <div className="col-22 col-lg-6 offset-1 offset-lg-2">
                    <FormRadio name="teli" id="teli1" value="Teli laterali" label="Solo teli laterali" handleChange={props.handleInputChange} checked={props.stepsData[props.currentType].teli.teli === 'Teli laterali'} />
                </div>
                <div className="col-22 col-lg-6 offset-1">
                    <FormRadio name="teli" id="teli2" value="Telo tetto" label="Solo telo tetto" handleChange={props.handleInputChange} checked={props.stepsData[props.currentType].teli.teli === 'Telo tetto'} />
                </div>
            </div>
        </div>
    )
}

export const ValidateTeli = (stepsData, currentStepData, currentType) => {
    let isValid = true
    let currentStep = stepsData[currentType]

    if (stepsData.tipologia_mezzo.tipo_mezzo!=='Motrice + rimorchio') {
        if(currentStep && !currentStep.teli.teli) {
            isValid = false
        }
    }
    else {
        if (currentStepData.substep===0) {
            isValid = true
        }
        else if (currentStepData.substep===1) {
            if(currentStep && !currentStep.teli.teli) {
                isValid = false
            }
        }
    }

    

    return isValid;
}

export default withRouter(Teli)
