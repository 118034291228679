import React from 'react';
import { withRouter } from "react-router-dom";

import Image from './Image.js';
import FormInput from '../Form/Input';

class SpallaTetto extends React.Component {
    constructor(props) {
        super(props);

        this.step = props.steps['spalla_tetto'];

        this.state = {
            nextStep: 'occhielli'
        }

        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount() {
        this.props.updateStep(this.step.id, this.step.name, this.state.nextStep, this.props.match.params.typeId)
    }

    handleInputChange = event => {
        const target = event.target;
        let value = target.getAttribute('type') === 'checkbox' ? target.checked : target.value;
        let name = target.name;

        if (name.indexOf('[')>0) {
            let newName = name.replace(/\[(\w+)]/g, '')
            let key = /\[(.*?)\]/g.exec(name)[1]

            let newValue = this.props.stepsData[this.props.currentType][this.step.id][newName]
            newValue[key] = value

            name = newName
            value = newValue
        }

        this.props.updateData(this.props.currentType, this.step.id, name, value)
    }

    render() {
        if (this.props.stepsData[this.props.currentType]) {
            return (
                <div className="step step__spalla-tetto">
                    <div className="row">
                        <div className="col-22 col-lg-20 col-xl-18 offset-1 offset-lg-2 offset-xl-3">
                            <div className="step__image">
                                <Image directory={this.props.currentStep.id} name="spalla_tetto" />
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-24">
                            <div className="row justify-content-center align-items-center">
                                <div className="col-24 col-lg-auto text-md-center text-lg-left">
                                    <span className="form-label">Spalla Tetto</span>
                                </div>
                                <div className="col-24 col-md-11 col-lg-4 no-form-margin-desktop">
                                    <FormInput type="number" name="d" id="spalla_d" value={this.props.stepsData[this.props.currentType].spalla_tetto.d} label="D" handleChange={this.handleInputChange} />
                                </div>
                                <div className="col-24 col-md-11 col-lg-4 no-form-margin-desktop">
                                    <FormInput type="number" name="e" id="spalla_e" value={this.props.stepsData[this.props.currentType].spalla_tetto.e} label="E" handleChange={this.handleInputChange} />
                                </div>
                                <div className="col-24 col-md-11 col-lg-4 no-form-margin-desktop">
                                    <FormInput type="number" name="f" id="spalla_f" value={this.props.stepsData[this.props.currentType].spalla_tetto.f} label="F" handleChange={this.handleInputChange} />
                                </div>
                                <div className="col-24 col-md-11 col-lg-4 no-form-margin-desktop">
                                    <FormInput type="number" name="g" id="spalla_g" value={this.props.stepsData[this.props.currentType].spalla_tetto.g} label="G" handleChange={this.handleInputChange} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return null
        }
    }
}

export const ValidateSpallaTetto = (stepsData, currentStepData, currentType) => {
    let isValid = true
    let currentStep = stepsData[currentType].spalla_tetto

    if(!currentStep.d || !currentStep.e || !currentStep.f || !currentStep.g) {
        isValid = false
    }

    return isValid;
}

export default withRouter(SpallaTetto)
