import React from 'react';
import SimpleStorage from "react-simple-storage";
import { withRouter } from "react-router-dom";

import FormRadio from '../Form/Radio';

class TipologiaTetto extends React.Component {
    constructor(props) {
        super(props);

        this.step = props.steps['nodo'];

        this.state = {
            nextStep: 'traversi'
        }

        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount() {
        this.props.updateStep(this.step.id, this.step.name, this.state.nextStep, this.props.match.params.typeId)
    }

    handleInputChange = event => {
        const target = event.target;
        let value = target.getAttribute('type') === 'checkbox' ? target.checked : target.value;
        let name = target.name;

        if (name.indexOf('[')>0) {
            let newName = name.replace(/\[(\w+)]/g, '')
            let key = /\[(.*?)\]/g.exec(name)[1]

            let newValue = this.props.stepsData[this.props.currentType][this.step.id][newName]
            newValue[key] = value

            name = newName
            value = newValue
        }

        this.props.updateData(this.props.currentType, this.step.id, name, value)
    }

    updateNextStep = (tetto) => {
        let nextStep = 'archi'

        if (tetto==='Tetto fisso') {
            nextStep = 'traversi'
        }

        this.setState({ 
            nextStep: nextStep
        }, () => {
            this.props.updateStep(this.step.id, this.step.name, this.state.nextStep)
        })
    }

    render() {
        if (this.props.stepsData[this.props.currentType]) {
            return (
                <div className="step step__nodo">
                    <SimpleStorage parent={this} prefix={"Configurator"} onParentStateHydrated={() => {
                        this.updateNextStep(this.props.stepsData[this.props.currentType].tipologia_tetto.tipologia_tetto)
                    }} />
                    <div className="row justify-content-center">
                        <div className="col-22 col-md-5 col-lg-4 offset-1 offset-md-0">
                            <FormRadio name="nodo" id="nodo1" value="Normale" label="Normale" handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].nodo.nodo === 'Normale'} />
                        </div>
                        <div className="col-22 col-md-5 col-lg-4 offset-lg-1">
                            <FormRadio name="nodo" id="nodo2" value="Carapax" label="Carapax" handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].nodo.nodo === 'Carapax'} />
                        </div>
                        <div className="col-22 col-md-5 col-lg-4 offset-lg-1">
                            <FormRadio name="nodo" id="nodo3" value="Ignifugo" label="Ignifugo" handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].nodo.nodo === 'Ignifugo'} />
                        </div>
                        <div className="col-22 col-md-5 col-lg-4 offset-lg-1">
                            <FormRadio name="nodo" id="nodo4" value="Coibentato" label="Coibentato" handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].nodo.nodo === 'Coibentato'} />
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return null
        }
    }
}

export const ValidateNodo = (stepsData, currentStepData, currentType) => {
    let isValid = true
    let currentStep = stepsData[currentType].nodo

    if(!currentStep.nodo) {
        isValid = false
    }

    return isValid;
}

export default withRouter(TipologiaTetto)
