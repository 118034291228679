import React from 'react';
import { withRouter } from "react-router-dom";

import Image from './Image.js';
import FormInput from '../Form/Input';

class RetroporteA extends React.Component {
    constructor(props) {
        super(props);

        this.step = props.steps['retroporte_a'];

        this.state = {
            nextStep: 'opzioni'
        }

        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount() {
        this.props.updateStep(this.step.id, this.step.name, this.state.nextStep, 'other')
    }

    handleInputChange = event => {
        const target = event.target;
        let value = target.getAttribute('type') === 'checkbox' ? target.checked : target.value;
        let name = target.name;

        if (name.indexOf('[')>0) {
            let newName = name.replace(/\[(\w+)]/g, '')
            let key = /\[(.*?)\]/g.exec(name)[1]

            let newValue = this.props.stepsData[this.props.currentType][this.step.id][newName]
            newValue[key] = value

            name = newName
            value = newValue
        }

        this.props.updateData(this.props.currentType, this.step.id, name, value)
    }

    render() {
        if (this.props.stepsData) {
            return (
                <div className="step step__retroporte-a">
                    <div className="row">
                        <div className="col-22 col-lg-20 col-xl-18 offset-1 offset-lg-2 offset-xl-3">
                            <div className="step__image">
                                <Image directory={this.props.currentStep.id} name="retroporte_a" />
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center align-items-center">
                        <div className="col-24 col-xl-auto text-md-center text-lg-center text-xl-left">
                            <span className="form-label d-block">Dimensioni</span>
                            <br className="d-none d-lg-inline d-xl-none"/>
                        </div>
                        <div className="col-24 col-md-7 col-lg-4 col-xl-3 no-form-margin-desktop">
                            <FormInput type="number" name="la" id="la" value={this.props.stepsData.retroporte_a.la} label="LA" handleChange={this.handleInputChange} />
                        </div>
                        <div className="col-24 col-md-7 col-lg-4 col-xl-3 no-form-margin-desktop">
                            <FormInput type="number" name="l1" id="l1" value={this.props.stepsData.retroporte_a.l1} label="L1" handleChange={this.handleInputChange} />
                        </div>
                        <div className="col-24 col-md-7 col-lg-4 col-xl-3 no-form-margin-desktop">
                            <FormInput type="number" name="l2" id="l2" value={this.props.stepsData.retroporte_a.l2} label="L2" handleChange={this.handleInputChange} />
                        </div>
                        <div className="col-24 col-md-7 col-lg-4 col-xl-3 no-form-margin-desktop">
                            <FormInput type="number" name="l3" id="l3" value={this.props.stepsData.retroporte_a.l3} label="L3" handleChange={this.handleInputChange} />
                        </div>
                        <div className="col-24 col-md-7 col-lg-4 col-xl-3 no-form-margin-desktop">
                            <FormInput type="number" name="h" id="h" value={this.props.stepsData.retroporte_a.h} label="H" handleChange={this.handleInputChange} />
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return null
        }
    }
}

export const ValidateRetroporteA = (stepsData, currentStepData, currentType) => {
    let isValid = true
    let currentStep = stepsData.retroporte_a

    if(!currentStep.la || !currentStep.l1 || !currentStep.l2 || !currentStep.l3 || !currentStep.h) {
        isValid = false
    }

    return isValid;
}

export default withRouter(RetroporteA)
