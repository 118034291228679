import React from 'react';
import SimpleStorage from "react-simple-storage";
import { withRouter } from "react-router-dom";

import Image from './Image.js';
import FormInput from '../Form/Input';

class DistanzaPiantoni extends React.Component {
    constructor(props) {
        super(props);

        this.step = props.steps['distanza_piantoni'];

        this.state = {
            nextStep: 'tipologia_carrelli'
        }

        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount() {
        this.props.updateStep(this.step.id, this.step.name, this.state.nextStep, this.props.match.params.typeId)
    }

    handleInputChange = event => {
        const target = event.target;
        let value = target.getAttribute('type') === 'checkbox' ? target.checked : target.value;
        let name = target.name;

        if (name.indexOf('[')>0) {
            let newName = name.replace(/\[(\w+)]/g, '')
            let key = /\[(.*?)\]/g.exec(name)[1]

            let newValue = this.props.stepsData[this.props.currentType][this.step.id][newName]
            newValue[key] = value

            name = newName
            value = newValue
        }

        this.props.updateData(this.props.currentType, this.step.id, name, value)
    }

    updateNextStep = (teli) => {
        let nextStep = 'tipologia_carrelli';

        if (this.props.stepsData[this.props.currentType].teli_laterali.teli_laterali[1]==='Fisso') {
            nextStep = 'tenditelo';
        }

        this.setState({ 
            nextStep: nextStep
        }, () => {
            this.props.updateStep(this.step.id, this.step.name, this.state.nextStep)
        })
    }

    render() {
        if (this.props.stepsData[this.props.currentType]) {
            return (
                <div className="step step__distanza-piantoni">
                    <SimpleStorage parent={this} prefix={"Configurator"} onParentStateHydrated={() => {
                        this.updateNextStep()
                    }} />
                    <div className="row">
                        <div className="col-22 col-lg-20 col-xl-18 offset-1 offset-lg-2 offset-xl-3">
                            <div className="step__image">
                                <Image directory="ganci_sponda" name="piantone_2" />
                            </div>
                            <div className="step__form">
                                <div className="step__title">Distanza Primo Ultimo Piantone</div>
                                <div className="row">
                                    <div className="col-24 col-md-10 col-xl-8 offset-md-2 offset-xl-4">
                                        <FormInput type="number" name="passo_anteriore" id="passo_anteriore" value={this.props.stepsData[this.props.currentType].distanza_piantoni.passo_anteriore} label="Passo anteriore" handleChange={this.handleInputChange} />
                                    </div>
                                    <div className="col-24 col-md-10 col-xl-8">
                                        <FormInput type="number" name="passo_posteriore" id="passo_posteriore" value={this.props.stepsData[this.props.currentType].distanza_piantoni.passo_posteriore} label="Passo posteriore" handleChange={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return null
        }
    }
}

export const ValidateDistanzaPiantoni = (stepsData, currentStepData, currentType) => {
    let isValid = true
    let currentStep = stepsData[currentType].distanza_piantoni

    if(!currentStep.passo_anteriore || !currentStep.passo_posteriore) {
        isValid = false
    }

    return isValid;
}

export default withRouter(DistanzaPiantoni)
