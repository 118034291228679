import React from 'react';
import { withRouter } from "react-router-dom";

import Accordion from 'react-bootstrap/Accordion'
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';
import Card from 'react-bootstrap/Card'

import FormInput from '../Form/Input';
import FormRadio from '../Form/Radio';

import 'react-dates/initialize';
import { SingleDatePicker } from 'react-dates';
import itLocale from 'moment/locale/it';
import moment from 'moment';

class DatiCliente extends React.Component {
    constructor(props) {
        super(props);

        this.step = props.steps['dati_cliente'];

        this.state = {
            nextStep: 'teli',
            accordionActiveElement: 0,
            datepickerFocused: false
        }

        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount() {
        this.props.updateStep(this.step.id, this.step.name, this.state.nextStep, 'other')
    }

    handleInputChange = event => {
        const target = event.target;
        let value = target.getAttribute('type') === 'checkbox' ? target.checked : target.value;
        let name = target.name;

        // if (name.indexOf('[')>0) {
        //     let newName = name.replace(/\[(\w+)]/g, '')
        //     let key = /\[(.*?)\]/g.exec(name)[1]

        //     let newValue = this.props.stepsData[this.step.id][newName]
        //     newValue[key] = value

        //     name = newName
        //     value = newValue
        // }

        this.props.updateData(this.props.currentType, this.step.id, name, value)
    }

    handleAccordionToggle = (eventKey) => {
        if (parseInt(eventKey)!==this.state.accordionActiveElement) {
            this.setState({
                accordionActiveElement: parseInt(eventKey)
            }, () => {
            })
        }
        else {
            this.setState({
                accordionActiveElement: null
            }, () => {
            })
        }
    }

    cardClass = (eventKey) => {
        let htmlClass = 'card'

        if (parseInt(eventKey)===parseInt(this.state.accordionActiveElement)) {
            htmlClass += ' show'
        }

        if (this.props.stepsAccordionValidation[this.step.id] && this.props.stepsAccordionValidation[this.step.id][eventKey]) {
            htmlClass += ' valid'
        }

        return htmlClass;
    }

    getDate = () => {
        let data_consegna = moment(this.props.stepsData.dati_cliente.dati_lavorazione.data_consegna)
        if (data_consegna._isValid) {
            return data_consegna
        }
        else {
            return null
        }
    }

    setDate = (date) => {
        this.props.updateData(this.props.currentType, this.step.id, 'dati_lavorazione[data_consegna]', date)
    }

    setFocused = (focused) => {
        this.setState({
            datepickerFocused: focused
        }, () => {
        })
    }

    render() {
        moment.updateLocale('it', itLocale)

        return (
            <div className="step step__dati-cliente">
                <div className="row justify-content-center">
                    <div className="col-24 col-lg-20">
                        <Accordion defaultActiveKey="0">
                            <Card bsPrefix={this.cardClass(0)}>
                                <Toggle eventKey="0" callback={this.handleAccordionToggle}>
                                    <span>Dati Principali</span>
                                </Toggle>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body>
                                        <div className="row">
                                            <div className="col-24 col-lg-12">
                                                <FormInput type="text" name="dati_principali[azienda]" id="azienda" value={this.props.stepsData.dati_cliente.dati_principali.azienda} label="Azienda" handleChange={this.handleInputChange} />
                                            </div>
                                            <div className="col-24 col-lg-12">
                                                <FormInput type="text" name="dati_principali[referente]" id="referente" value={this.props.stepsData.dati_cliente.dati_principali.referente} label="Referente" handleChange={this.handleInputChange} />
                                            </div>
                                            <div className="col-24 col-lg-12">
                                                <FormInput type="text" name="dati_principali[telefono]" id="telefono" value={this.props.stepsData.dati_cliente.dati_principali.telefono} label="Telefono" handleChange={this.handleInputChange} />
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>

                            <Card bsPrefix={this.cardClass(1)}>
                                <Toggle eventKey="1" callback={this.handleAccordionToggle}>
                                    <span>Dati Mezzo</span>
                                </Toggle>
                                <Accordion.Collapse eventKey="1">
                                    <Card.Body>
                                        <div className="row">
                                            <div className="col-24 col-lg-12">
                                                <FormInput type="text" name="dati_mezzo[targa]" id="targa" value={this.props.stepsData.dati_cliente.dati_mezzo.targa} label="Targa" optional={true} handleChange={this.handleInputChange} />
                                            </div>
                                            <div className="col-24 col-lg-12">
                                                <FormInput type="text" name="dati_mezzo[marca]" id="marca" value={this.props.stepsData.dati_cliente.dati_mezzo.marca} label="Marca" optional={true} handleChange={this.handleInputChange} />
                                            </div>
                                            <div className="col-24 col-lg-12">
                                                <FormInput type="text" name="dati_mezzo[modello]" id="modello" value={this.props.stepsData.dati_cliente.dati_mezzo.modello} label="Modello" optional={true} handleChange={this.handleInputChange} />
                                            </div>
                                            <div className="col-24 col-lg-12">
                                                <FormInput type="text" name="dati_mezzo[anno]" id="anno" value={this.props.stepsData.dati_cliente.dati_mezzo.anno} label="Anno" optional={true} handleChange={this.handleInputChange} />
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>

                            <Card bsPrefix={this.cardClass(2)}>
                                <Toggle eventKey="2" callback={this.handleAccordionToggle}>
                                    <span>Dati Lavorazione</span>
                                </Toggle>
                                <Accordion.Collapse eventKey="2">
                                    <Card.Body>
                                        <div className="row align-items-center">
                                            <div className="col-24 col-lg-11">
                                                <div className="row align-items-center">
                                                    <div className="col-24 col-lg-8">
                                                        <span className="form-label">Montaggio</span>
                                                    </div>
                                                    <div className="col-24 col-lg-8">
                                                        <FormRadio name="dati_lavorazione[montaggio]" id="montaggio_si" value="Sì" label="Sì" handleChange={this.handleInputChange} checked={this.props.stepsData.dati_cliente.dati_lavorazione.montaggio === 'Sì'} />
                                                    </div>
                                                    <div className="col-24 col-lg-8">
                                                        <FormRadio name="dati_lavorazione[montaggio]" id="montaggio_no" value="No" label="No" handleChange={this.handleInputChange} checked={this.props.stepsData.dati_cliente.dati_lavorazione.montaggio === 'No'} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-24 col-lg-11 offset-lg-2 no-form-margin-desktop">
                                                <div className={this.state.datepickerFocused || this.getDate() ? "form-group datepicker focused" : "form-group datepicker"}>
                                                    <label htmlFor="date">Data consegna <span>(facoltativo)</span></label>
                                                    <SingleDatePicker
                                                        date={this.getDate()}
                                                        onDateChange={date => this.setDate(date)}
                                                        focused={this.state.datepickerFocused}
                                                        onFocusChange={({ focused }) => this.setFocused(focused)}
                                                        id="date"
                                                        placeholder="__/__/____"
                                                        required={false}
                                                        showDefaultInputIcon={true}
                                                        inputIconPosition={"after"}
                                                        numberOfMonths={1}
                                                        openDirection={"up"}
                                                        displayFormat="DD/MM/Y"
                                                        noBorder={true}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                    </div>
                </div>
            </div>
        );
    }
}

const Toggle = ({ children, eventKey, callback }) => {
    const decoratedOnClick = useAccordionToggle(
        eventKey,
        () => callback && callback(eventKey),
    );

    return (
        <Card.Header onClick={decoratedOnClick}>
            {children}
        </Card.Header>
    );
}

export const ValidateDatiCliente = (stepsData, currentStepData) => {
    let isValid = true
    let currentStep = stepsData.dati_cliente

    if(!currentStep.dati_principali.azienda || !currentStep.dati_principali.referente || !currentStep.dati_principali.telefono || !currentStep.dati_lavorazione.montaggio) {
        isValid = false
    }

    return isValid;
}

export const ValidateDatiClienteAccordion = (stepsData, currentStepData) => {
    let isValid = [true, true, true]
    let currentStep = stepsData.dati_cliente

    if(!currentStep.dati_principali.azienda || !currentStep.dati_principali.referente || !currentStep.dati_principali.telefono) {
        isValid[0] = false
    }

    if(!currentStep.dati_mezzo.targa && !currentStep.dati_mezzo.marca && !currentStep.dati_mezzo.modello && !currentStep.dati_mezzo.anno) {
        isValid[1] = false
    }

    if(!currentStep.dati_lavorazione.montaggio) {
        isValid[2] = false
    }

    return isValid;
}

export default withRouter(DatiCliente)