import React from 'react';
import SimpleStorage, { clearStorage } from "react-simple-storage";

import camio from '../images/camio.png';

class Home extends React.Component {
    constructor() {
        super()

        this.state = {
            pippo: ''
        }
        
        clearStorage("Configurator")
    }
    
    render() {
        return (
            <div>
                <SimpleStorage parent={this} prefix={"Configurator"} />

                <div className="front-page">
                    <div className="container-fluid">
                        <div className="row align-items-center">
                            <div className="col-24 col-lg-14 col-xl-16">
                                <div className="front-page__image">
                                    <a href="https://telonitosetto.it/" className="d-none d-lg-inline">Ritorna al sito</a>
                                    <img src={camio} alt="" className="img-fluid" />
                                </div>
                            </div>
                            <div className="col-24 col-md-20 col-lg-8 col-xl-8 col-xxl-7 offset-md-2 offset-lg-1">
                                <div className="front-page__content text-center text-lg-left">
                                    <svg className="svg-icon svg-icon--logo" viewBox="0 0 598 223" preserveAspectRatio="xMidYMid meet" aria-hidden="true">
                                        <use xlinkHref="#svg-icon__logo"></use>
                                    </svg>
                                    <h1 className="title"><strong>Lorem ipsum dolor sit amet</strong>, consectetur adipisicing elit,</h1>
                                    <a href="/configurator/tipologia-mezzo" className="btn btn-primary btn-lg btn-lg-block">Configura Ora</a><br/>
                                    <a href="/area-riservata" className="btn btn-link btn-lg">Area Riservata</a>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-24 col-lg-15 offset-lg-1">
                                <div className="front-page__copyright text-center text-lg-left">
                                    &copy; 2020 Teloni Tosetto srl Unipersonale. All Rights Reserved  -  C.F., P.IVA e Reg. Imprese PD: 04245050283
                                </div>
                            </div>
                            <div className="col-24 col-lg-7 text-lg-right">
                                <div className="front-page__legal text-center text-lg-right">
                                    <a href="https://telonitosetto.it/privacy-policy/" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
                                    <a href="https://telonitosetto.it/cookies/" target="_blank" rel="noopener noreferrer">Cookie Policy</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Home;
