import React from 'react';
import { withRouter } from "react-router-dom";

import FormInput from '../Form/Input';

class OpzioniRollfixPlaneroller extends React.Component {
    constructor(props) {
        super(props);

        this.step = props.steps['opzioni_rollfix_planeroller'];

        this.state = {
            nextStep: 'note'
        }

        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount() {
        this.props.updateStep(this.step.id, this.step.name, this.state.nextStep, 'other')
    }

    handleInputChange = event => {
        const target = event.target;
        let value = target.getAttribute('type') === 'checkbox' ? target.checked : target.value;
        let name = target.name;

        if (name.indexOf('[')>0) {
            let newName = name.replace(/\[(\w+)]/g, '')
            let key = /\[(.*?)\]/g.exec(name)[1]

            let newValue = this.props.stepsData[this.props.currentType][this.step.id][newName]
            newValue[key] = value

            name = newName
            value = newValue
        }

        this.props.updateData(this.props.currentType, this.step.id, name, value)
    }

    render() {
        if (this.props.stepsData) {
            return (
                <div className="step step__opzioni">
                    <div className="row justify-content-center">
                        <div className="col-22 col-lg-14 col-xl-12">
                            <div className="step__option">
                                <div className="row align-items-center">
                                    <div className="col-18">
                                        <div className="step__option__label">Reflex Bianco</div>
                                    </div>
                                    <div className="col-6">
                                        <FormInput type="number" name="opt_1" id="opt_1" value={this.props.stepsData.opzioni_rollfix_planeroller.opt_1} label="Ml." handleChange={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="step__option">
                                <div className="row align-items-center">
                                    <div className="col-18">
                                        <div className="step__option__label">Reflex Giallo</div>
                                    </div>
                                    <div className="col-6">
                                        <FormInput type="number" name="opt_2" id="opt_2" value={this.props.stepsData.opzioni_rollfix_planeroller.opt_2} label="Ml." handleChange={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="step__option">
                                <div className="row align-items-center">
                                    <div className="col-18">
                                        <div className="step__option__label">Angolare 40x40x2 Alluminio</div>
                                    </div>
                                    <div className="col-6">
                                        <FormInput type="number" name="opt_3" id="opt_3" value={this.props.stepsData.opzioni_rollfix_planeroller.opt_3} label="Ml." handleChange={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="step__option">
                                <div className="row align-items-center">
                                    <div className="col-18">
                                        <div className="step__option__label">Bordino D.10 mm</div>
                                    </div>
                                    <div className="col-6">
                                        <FormInput type="number" name="opt_4" id="opt_4" value={this.props.stepsData.opzioni_rollfix_planeroller.opt_4} label="Ml." handleChange={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="step__option">
                                <div className="row align-items-center">
                                    <div className="col-18">
                                        <div className="step__option__label">Bordino D.7,5 mm</div>
                                    </div>
                                    <div className="col-6">
                                        <FormInput type="number" name="opt_5" id="opt_5" value={this.props.stepsData.opzioni_rollfix_planeroller.opt_5} label="Ml." handleChange={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="step__option">
                                <div className="row align-items-center">
                                    <div className="col-18">
                                        <div className="step__option__label">Bordino D.11 mm</div>
                                    </div>
                                    <div className="col-6">
                                        <FormInput type="number" name="opt_6" id="opt_6" value={this.props.stepsData.opzioni_rollfix_planeroller.opt_6} label="Ml." handleChange={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="step__option">
                                <div className="row align-items-center">
                                    <div className="col-18">
                                        <div className="step__option__label">Bordino D.13 mm</div>
                                    </div>
                                    <div className="col-6">
                                        <FormInput type="number" name="opt_7" id="opt_7" value={this.props.stepsData.opzioni_rollfix_planeroller.opt_7} label="Ml." handleChange={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="step__option">
                                <div className="row align-items-center">
                                    <div className="col-18">
                                        <div className="step__option__label">Calotta In PVC D.43 mm</div>
                                    </div>
                                    <div className="col-6">
                                        <FormInput type="number" name="opt_8" id="opt_8" value={this.props.stepsData.opzioni_rollfix_planeroller.opt_8} label="Nr." handleChange={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="step__option">
                                <div className="row align-items-center">
                                    <div className="col-18">
                                        <div className="step__option__label">Molla Filo 2 mm D.43 mm L.600 mm</div>
                                    </div>
                                    <div className="col-6">
                                        <FormInput type="number" name="opt_9" id="opt_9" value={this.props.stepsData.opzioni_rollfix_planeroller.opt_9} label="Nr." handleChange={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="step__option">
                                <div className="row align-items-center">
                                    <div className="col-18">
                                        <div className="step__option__label">Molla Inox Da 1500 mm</div>
                                    </div>
                                    <div className="col-6">
                                        <FormInput type="number" name="opt_10" id="opt_10" value={this.props.stepsData.opzioni_rollfix_planeroller.opt_10} label="Nr." handleChange={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="step__option">
                                <div className="row align-items-center">
                                    <div className="col-18">
                                        <div className="step__option__label">Profilo Doppia Ogiva</div>
                                    </div>
                                    <div className="col-6">
                                        <FormInput type="number" name="opt_11" id="opt_11" value={this.props.stepsData.opzioni_rollfix_planeroller.opt_11} label="Ml." handleChange={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="step__option">
                                <div className="row align-items-center">
                                    <div className="col-18">
                                        <div className="step__option__label">Supporto Per Molla D.43 mm</div>
                                    </div>
                                    <div className="col-6">
                                        <FormInput type="number" name="opt_12" id="opt_12" value={this.props.stepsData.opzioni_rollfix_planeroller.opt_12} label="Nr." handleChange={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="step__option">
                                <div className="row align-items-center">
                                    <div className="col-18">
                                        <div className="step__option__label">Tappi Per Profilo</div>
                                    </div>
                                    <div className="col-6">
                                        <FormInput type="number" name="opt_13" id="opt_13" value={this.props.stepsData.opzioni_rollfix_planeroller.opt_13} label="Nr." handleChange={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="step__option">
                                <div className="row align-items-center">
                                    <div className="col-18">
                                        <div className="step__option__label">Tubo C/Ogiva D.43 mm L.2550 mm</div>
                                    </div>
                                    <div className="col-6">
                                        <FormInput type="number" name="opt_14" id="opt_14" value={this.props.stepsData.opzioni_rollfix_planeroller.opt_14} label="Ml." handleChange={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="step__option">
                                <div className="row align-items-center">
                                    <div className="col-18">
                                        <div className="step__option__label">Statistica € + C.F. = Costo</div>
                                    </div>
                                    <div className="col-6">
                                        <FormInput type="number" name="opt_15" id="opt_15" value={this.props.stepsData.opzioni_rollfix_planeroller.opt_15} label="Nr." handleChange={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return null
        }
    }
}

export const ValidateOpzioniRollfixPlaneroller = (stepsData, currentStepData, currentType) => {
    let isValid = true
    //let currentStep = stepsData.opzioni_rollfix_planeroller

    return isValid;
}

export default withRouter(OpzioniRollfixPlaneroller)
