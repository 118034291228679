import React from 'react';
import { withRouter } from "react-router-dom";

import FormInput from '../Form/Input';

class OpzioniProntocar extends React.Component {
    constructor(props) {
        super(props);

        this.step = props.steps['opzioni_prontocar'];

        this.state = {
            nextStep: 'opzioni_rollfix_planeroller'
        }

        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount() {
        this.props.updateStep(this.step.id, this.step.name, this.state.nextStep, 'other')
    }

    handleInputChange = event => {
        const target = event.target;
        let value = target.getAttribute('type') === 'checkbox' ? target.checked : target.value;
        let name = target.name;

        if (name.indexOf('[')>0) {
            let newName = name.replace(/\[(\w+)]/g, '')
            let key = /\[(.*?)\]/g.exec(name)[1]

            let newValue = this.props.stepsData[this.props.currentType][this.step.id][newName]
            newValue[key] = value

            name = newName
            value = newValue
        }

        this.props.updateData(this.props.currentType, this.step.id, name, value)
    }

    render() {
        if (this.props.stepsData) {
            return (
                <div className="step step__opzioni">
                    <div className="row justify-content-center">
                        <div className="col-22 col-lg-14 col-xl-12">
                            <div className="step__option">
                                <div className="row align-items-center">
                                    <div className="col-18">
                                        <div className="step__option__label">Cinghia Spalmata P.Car</div>
                                    </div>
                                    <div className="col-6">
                                        <FormInput type="number" name="opt_1" id="opt_1" value={this.props.stepsData.opzioni_prontocar.opt_1} label="Ml." handleChange={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="step__option">
                                <div className="row align-items-center">
                                    <div className="col-18">
                                        <div className="step__option__label">Cricchetto Inox C/Cinghia G. Tranc</div>
                                    </div>
                                    <div className="col-6">
                                        <FormInput type="number" name="opt_2" id="opt_2" value={this.props.stepsData.opzioni_prontocar.opt_2} label="Nr." handleChange={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="step__option">
                                <div className="row align-items-center">
                                    <div className="col-18">
                                        <div className="step__option__label">Cricchetto Inox C/Cinghia C/Sicura</div>
                                    </div>
                                    <div className="col-6">
                                        <FormInput type="number" name="opt_3" id="opt_3" value={this.props.stepsData.opzioni_prontocar.opt_3} label="Nr." handleChange={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="step__option">
                                <div className="row align-items-center">
                                    <div className="col-18">
                                        <div className="step__option__label">Cricchetto Plastica Rosso C/Cinghia</div>
                                    </div>
                                    <div className="col-6">
                                        <FormInput type="number" name="opt_4" id="opt_4" value={this.props.stepsData.opzioni_prontocar.opt_4} label="Nr." handleChange={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="step__option">
                                <div className="row align-items-center">
                                    <div className="col-18">
                                        <div className="step__option__label">Cricchetto P.Car Inox Diretto</div>
                                    </div>
                                    <div className="col-6">
                                        <FormInput type="number" name="opt_5" id="opt_5" value={this.props.stepsData.opzioni_prontocar.opt_5} label="Nr." handleChange={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="step__option">
                                <div className="row align-items-center">
                                    <div className="col-18">
                                        <div className="step__option__label">Cinghia Ricambio Cricchetto P.Car</div>
                                    </div>
                                    <div className="col-6">
                                        <FormInput type="number" name="opt_6" id="opt_6" value={this.props.stepsData.opzioni_prontocar.opt_6} label="Nr." handleChange={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="step__option">
                                <div className="row align-items-center">
                                    <div className="col-18">
                                        <div className="step__option__label">Barra Tenditelo Alluminio D.27 mm</div>
                                    </div>
                                    <div className="col-6">
                                        <FormInput type="number" name="opt_7" id="opt_7" value={this.props.stepsData.opzioni_prontocar.opt_7} label="Nr." handleChange={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="step__option">
                                <div className="row align-items-center">
                                    <div className="col-18">
                                        <div className="step__option__label">Barra Tenditelo Alluminio D.35 mm</div>
                                    </div>
                                    <div className="col-6">
                                        <FormInput type="number" name="opt_8" id="opt_8" value={this.props.stepsData.opzioni_prontocar.opt_8} label="Nr." handleChange={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="step__option">
                                <div className="row align-items-center">
                                    <div className="col-18">
                                        <div className="step__option__label">Perno Inferiore D.27 mm</div>
                                    </div>
                                    <div className="col-6">
                                        <FormInput type="number" name="opt_9" id="opt_9" value={this.props.stepsData.opzioni_prontocar.opt_9} label="Nr." handleChange={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="step__option">
                                <div className="row align-items-center">
                                    <div className="col-18">
                                        <div className="step__option__label">Perno Superiore D.27 mm</div>
                                    </div>
                                    <div className="col-6">
                                        <FormInput type="number" name="opt_10" id="opt_10" value={this.props.stepsData.opzioni_prontocar.opt_10} label="Nr." handleChange={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="step__option">
                                <div className="row align-items-center">
                                    <div className="col-18">
                                        <div className="step__option__label">Perno Inferiore D.35 mm</div>
                                    </div>
                                    <div className="col-6">
                                        <FormInput type="number" name="opt_11" id="opt_11" value={this.props.stepsData.opzioni_prontocar.opt_11} label="Nr." handleChange={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="step__option">
                                <div className="row align-items-center">
                                    <div className="col-18">
                                        <div className="step__option__label">Perno Superiore D.35 mm</div>
                                    </div>
                                    <div className="col-6">
                                        <FormInput type="number" name="opt_12" id="opt_12" value={this.props.stepsData.opzioni_prontocar.opt_12} label="Nr." handleChange={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="step__option">
                                <div className="row align-items-center">
                                    <div className="col-18">
                                        <div className="step__option__label">Perno Adattatore D.35 mm x Asta D. 27 mm</div>
                                    </div>
                                    <div className="col-6">
                                        <FormInput type="number" name="opt_13" id="opt_13" value={this.props.stepsData.opzioni_prontocar.opt_13} label="Nr." handleChange={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="step__option">
                                <div className="row align-items-center">
                                    <div className="col-18">
                                        <div className="step__option__label">Profilo Tensotel Tipo Nuovo</div>
                                    </div>
                                    <div className="col-6">
                                        <FormInput type="number" name="opt_14" id="opt_14" value={this.props.stepsData.opzioni_prontocar.opt_14} label="Ml." handleChange={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="step__option">
                                <div className="row align-items-center">
                                    <div className="col-18">
                                        <div className="step__option__label">Profilo Tensotel Tipo Vecchio</div>
                                    </div>
                                    <div className="col-6">
                                        <FormInput type="number" name="opt_15" id="opt_15" value={this.props.stepsData.opzioni_prontocar.opt_15} label="Ml." handleChange={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="step__option">
                                <div className="row align-items-center">
                                    <div className="col-18">
                                        <div className="step__option__label">Profilo Tensotel Tipo Piccolo</div>
                                    </div>
                                    <div className="col-6">
                                        <FormInput type="number" name="opt_16" id="opt_16" value={this.props.stepsData.opzioni_prontocar.opt_16} label="Ml." handleChange={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="step__option">
                                <div className="row align-items-center">
                                    <div className="col-18">
                                        <div className="step__option__label">Profilo Z Zincato</div>
                                    </div>
                                    <div className="col-6">
                                        <FormInput type="number" name="opt_17" id="opt_17" value={this.props.stepsData.opzioni_prontocar.opt_17} label="Nr." handleChange={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="step__option">
                                <div className="row align-items-center">
                                    <div className="col-18">
                                        <div className="step__option__label">Tubetto in PVC D.10 mm</div>
                                    </div>
                                    <div className="col-6">
                                        <FormInput type="number" name="opt_18" id="opt_18" value={this.props.stepsData.opzioni_prontocar.opt_18} label="Ml." handleChange={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="step__option">
                                <div className="row align-items-center">
                                    <div className="col-18">
                                        <div className="step__option__label">Tubetto in PVC D.12 mm</div>
                                    </div>
                                    <div className="col-6">
                                        <FormInput type="number" name="opt_19" id="opt_19" value={this.props.stepsData.opzioni_prontocar.opt_19} label="Ml." handleChange={this.handleInputChange} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return null
        }
    }
}

export const ValidateOpzioniProntocar = (stepsData, currentStepData, currentType) => {
    let isValid = true
    //let currentStep = stepsData.opzioni_prontocar

    return isValid;
}

export default withRouter(OpzioniProntocar)
