import React, { useState } from 'react';

import Nav from './Nav'

const Header = (props) => {

    const [show, setShow] = useState(false);

    const handleShow = () => {
        if(!show) {
            setShow(true)
        }
        else {
            setShow(false)
        }
    };


    let progress = 0
    let stepsCount = props.stepsCount //Object.keys(props.steps).length - 2

    if (props.currentStep.id) {
        let position = props.steps[props.currentStep.id].position

        if ( (props.stepsData['semirimorchio'] && props.stepsData['semirimorchio'].teli.teli==="Telo tetto") || (props.stepsData['motrice'] && props.stepsData['motrice'].teli.teli==="Telo tetto") || (props.stepsData['rimorchio'] && props.stepsData['rimorchio'].teli.teli==="Telo tetto")  ) {
            position = props.steps[props.currentStep.id].position2
            
            if (props.stepsData.tipologia_mezzo.tipo_mezzo==='Motrice + rimorchio' && props.steps[props.currentStep.id].position>2 && props.currentType==='other') {
                position = position + 22
            }
        }
        else if ( (props.stepsData['semirimorchio'] && props.stepsData['semirimorchio'].teli.teli==="Teli laterali") || (props.stepsData['motrice'] && props.stepsData['motrice'].teli.teli==="Teli laterali") || (props.stepsData['rimorchio'] && props.stepsData['rimorchio'].teli.teli==="Teli laterali")  ) {
            position = position - 10
            
            if (props.currentType==='other') {
                position = position - 11
            }

            if (props.stepsData.tipologia_mezzo.tipo_mezzo==='Motrice + rimorchio' && props.steps[props.currentStep.id].position>2 && props.currentType==='other') {
                position = position + 26
            }
        }
        else {
            if (props.stepsData.tipologia_mezzo.tipo_mezzo==='Motrice + rimorchio' && props.steps[props.currentStep.id].position>2 && props.currentType==='other') {
                position = position + 37
            }
        }

        if (props.steps[props.currentStep.id].position>1) {
            if (props.stepsData.tipologia_mezzo.tipo_mezzo==='Motrice + rimorchio') {
                if (props.currentType==='rimorchio' || props.currentType==='semirimorchio') {
                    position = position + stepsCount
                }
                stepsCount = stepsCount * 2
            }
        }

        progress = position / (stepsCount) * 100
        progress = parseFloat(progress.toFixed(4))
    }

    progress = progress + '%'

    const divStyle = {
        width: progress
    }

    return (
        <div className="configurator__header">
            <HeaderTop icon="logo-symbol" steps={props.steps} stepsData={props.stepsData} currentStep={props.currentStep} stepsValidation={props.stepsValidation} handleShow={handleShow} />
            
            <div className="header-progress-bar" style={divStyle}>
                <HeaderTop icon="logo-symbol-monocolor" steps={props.steps} stepsData={props.stepsData} currentStep={props.currentStep} stepsValidation={props.stepsValidation} handleShow={handleShow} />
            </div>

            <div className="navigation">
                <div className={show ? "navigation__content _shown" : "navigation__content"} onClick={handleShow}>
                    <button className="navigation__toggle" onClick={handleShow}>
                        <svg className="svg-icon svg-icon--close" viewBox="0 0 16 16" preserveAspectRatio="xMidYMid meet" aria-hidden="true">
                            <use xlinkHref="#svg-icon__close"></use>
                        </svg>
                    </button>
                    <span className="navigation-title">Il Tuo Percorso</span>
                    <Nav steps={props.steps} stepsData={props.stepsData} currentStep={props.currentStep} currentType={props.currentType} stepsValidation={props.stepsValidation} />
                </div>
            </div>
        </div>
    )
}

const HeaderTop = (props) => {
    return (
        <div className="container-fluid">
            <div className="row align-items-center justify-content-center">
                <div className="col-5 offset-1">
                    <svg className="svg-icon svg-icon--logo-symbol" viewBox="0 0 35 43" preserveAspectRatio="xMidYMid meet" aria-hidden="true">
                        <use xlinkHref={"#svg-icon__" + props.icon}></use>
                    </svg>
                </div>
                <div className="col-12">
                    <h1 className="title">{props.currentStep.name}</h1>
                </div>
                <div className="col-6">
                    <div className="navigation">
                        <button className="navigation__toggle" onClick={props.handleShow}>
                            <svg className="svg-icon svg-icon--list" viewBox="0 0 23 15" preserveAspectRatio="xMidYMid meet" aria-hidden="true">
                                <use xlinkHref="#svg-icon__list"></use>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header;
