import React from 'react';
import { withRouter } from "react-router-dom";

import Image from './Image.js';
import FormRadio from '../Form/Radio';
import FormInput from '../Form/Input.js';

class TipologiaCinghie extends React.Component {
    constructor(props) {
        super(props);

        this.step = props.steps['tipologia_cinghie'];

        this.state = {
            nextStep: 'reflex'
        }

        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount() {
        this.props.updateStep(this.step.id, this.step.name, this.state.nextStep, this.props.match.params.typeId)
    }

    handleInputChange = event => {
        const target = event.target;
        let value = target.getAttribute('type') === 'checkbox' ? target.checked : target.value;
        let name = target.name;

        if (name.indexOf('[')>0) {
            let newName = name.replace(/\[(\w+)]/g, '')
            let key = /\[(.*?)\]/g.exec(name)[1]

            let newValue = this.props.stepsData[this.props.currentType][this.step.id][newName]
            newValue[key] = value

            name = newName
            value = newValue
        }

        this.props.updateData(this.props.currentType, this.step.id, name, value)
    }

    render() {
        if (this.props.stepsData[this.props.currentType]) {
            return (
                <div className="step step__cinghie">
                    <div className="row">
                        <div className="col-22 col-lg-20 col-xl-18 offset-1 offset-lg-2 offset-xl-3">
                            <div className="step__image">
                                <Image directory={this.props.currentStep.id} name="tipologia-cinghie" />
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center justify-content-center bottom-fields">
                        <div className="col-20 col-md-10 col-lg-8 col-xl-5">
                            <FormInput type="number" name="numero_cinghie_orizzontali" id="numero_cinghie_orizzontali" value={this.props.stepsData[this.props.currentType].tipologia_cinghie.numero_cinghie_orizzontali} label="Numero Cinghie Oriz." handleChange={this.handleInputChange} />
                            <br className="d-lg-none"/><br className="d-none d-lg-inline"/>
                        </div>
                    </div>
                    <div className="row align-items-center justify-content-center bottom-fields">
                        <div className="col-20 col-md-13 col-lg-10 col-xl-10 offset-lg-1">
                            <div className="row align-items-center">
                                <div className="col-24 col-lg-9 col-xl-auto text-center text-lg-left">
                                    <span className="bottom-fields__fake-label">Cinghie Verticali<br/>2300 Kn  EN 12641-2 XL</span>
                                </div>
                                <div className="col-10 col-lg-7 col-xl-5 offset-2 offset-lg-1">
                                    <FormRadio name="cinghie_verticali_en" id="cinghie_verticali_en1" value="Sì" label="Sì" handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].tipologia_cinghie.cinghie_verticali_en === 'Sì'} />
                                </div>
                                <div className="col-10 col-lg-7 col-xl-5">
                                    <FormRadio name="cinghie_verticali_en" id="cinghie_verticali_en2" value="No" label="No" handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].tipologia_cinghie.cinghie_verticali_en === 'No'} />
                                </div>
                            </div>
                            <br className="d-lg-none"/><br className="d-lg-none"/>
                        </div>
                        <div className="col-20 col-md-13 col-lg-10 col-xl-10 offset-lg-1">
                            <div className="row align-items-center">
                                <div className="col-24 col-lg-9 col-xl-auto text-center text-lg-left">
                                    <span className="bottom-fields__fake-label">Cinghie oblique XL</span>
                                </div>
                                <div className="col-10 col-lg-7 col-xl-5 offset-2 offset-lg-1">
                                    <FormRadio name="cinghie_oblique_xl" id="cinghie_oblique_xl1" value="Sì" label="Sì" handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].tipologia_cinghie.cinghie_oblique_xl === 'Sì'} />
                                </div>
                                <div className="col-10 col-lg-7 col-xl-5">
                                    <FormRadio name="cinghie_oblique_xl" id="cinghie_oblique_xl2" value="No" label="No" handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].tipologia_cinghie.cinghie_oblique_xl === 'No'} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return null
        }
    }
}

export const ValidateTipologiaCinghie = (stepsData, currentStepData, currentType) => {
    let isValid = true
    let currentStep = stepsData[currentType].tipologia_cinghie

    if(!currentStep.numero_cinghie_orizzontali || !currentStep.cinghie_verticali_en || !currentStep.cinghie_oblique_xl) {
        isValid = false
    }

    return isValid;
}

export default withRouter(TipologiaCinghie)
