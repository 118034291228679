import React from 'react';
import { withRouter } from "react-router-dom";

import Accordion from 'react-bootstrap/Accordion'
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';
import Card from 'react-bootstrap/Card'

import Image from './Image.js';
import FormSelect from '../Form/Select';
import FormRadio from '../Form/Radio';
import FormCheckbox from '../Form/Checkbox';

class MaterialeLaterale extends React.Component {
    constructor(props) {
        super(props);

        this.step = props.steps['materiale_laterale'];

        this.state = {
            nextStep: 'misure_laterale',
            accordionActiveElement: 0
        }

        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount() {
        this.props.updateStep(this.step.id, this.step.name, this.state.nextStep, this.props.match.params.typeId)
    }

    handleInputChange = event => {
        const target = event.target;
        let value = target.getAttribute('type') === 'checkbox' ? target.checked : target.value;
        let name = target.name;

        // if (name.indexOf('[')>0) {
        //     let newName = name.replace(/\[(\w+)]/g, '')
        //     let key = /\[(.*?)\]/g.exec(name)[1]

        //     let newValue = this.props.stepsData[this.step.id][newName]
        //     newValue[key] = value

        //     name = newName
        //     value = newValue
        // }

        this.props.updateData(this.props.currentType, this.step.id, name, value)
    }

    handleAccordionToggle = (eventKey) => {
        if (parseInt(eventKey)!==this.state.accordionActiveElement) {
            this.setState({
                accordionActiveElement: parseInt(eventKey)
            }, () => {
            })
        }
        else {
            this.setState({
                accordionActiveElement: null
            }, () => {
            })
        }
    }

    cardClass = (eventKey) => {
        let htmlClass = 'card'

        if (parseInt(eventKey)===parseInt(this.state.accordionActiveElement)) {
            htmlClass += ' show'
        }

        if (this.props.stepsAccordionValidation[this.step.id] && this.props.stepsAccordionValidation[this.step.id][eventKey]) {
            htmlClass += ' valid'
        }

        return htmlClass;
    }

    render() {
        return (
            <div className="step step__materiale-laterale">
                <div className="row justify-content-center">
                    <div className="col-24 col-lg-20">
                        <div className="text-center">
                            <Image directory="mezzo" name="mezzo" changeType={true} currentType={this.props.stepsData.tipologia_mezzo.tipo_mezzo} currentSubtype={this.props.currentType} stepsData={this.props.stepsData} changeLaterali={true} currentLaterali={this.props.stepsData[this.props.currentType].teli_laterali.teli_laterali[0]} />
                        </div>
                        <Accordion defaultActiveKey="0">
                            <Card bsPrefix={this.cardClass(0)}>
                                <Toggle eventKey="0" callback={this.handleAccordionToggle}>
                                    <span>Materiale</span>
                                </Toggle>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body>
                                        <div className="row align-items-center">
                                            <div className="col-24 col-lg-12">
                                                <div className="row">
                                                    <div className={ this.props.stepsData[this.props.currentType].teli_laterali.teli_laterali[0]==="Spondato" ? "col-24 col-md-12" : "col-24 col-md-12 d-none"}>
                                                        <FormRadio name="materiale[materiale]" id="680g" value="680g" label="680g" handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].materiale_laterale.materiale.materiale === '680g'} />
                                                    </div>
                                                    <div className="col-24 col-md-12">
                                                        <FormRadio name="materiale[materiale]" id="900g" value="900g Panama" label="900g Panama" handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].materiale_laterale.materiale.materiale === '900g Panama'} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-24 col-lg-11 offset-lg-1">
                                                <div className="row align-items-center">
                                                    <div className="col-auto">
                                                        Tipologia Materiale
                                                    </div>
                                                    <div className="col-11 no-form-margin-mobile">
                                                        <FormCheckbox name="materiale[tipologia]" id="ignifugo" value="Ignifugo" label="Ignifugo" handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].materiale_laterale.materiale.tipologia} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>

                            <Card bsPrefix={this.cardClass(1)}>
                                <Toggle eventKey="1" callback={this.handleAccordionToggle}>
                                    <span>Opzione Colore</span>
                                </Toggle>
                                <Accordion.Collapse eventKey="1">
                                    <Card.Body>
                                        <div className="row align-items-center">
                                            <div className="col-24 col-md-12">
                                                <FormSelect type="text" name="colore[colore_fascia]" id="colore_fascia" value={this.props.stepsData[this.props.currentType].materiale_laterale.colore.colore_fascia} label="Opzione Colore Fascia" optional={true} handleChange={this.handleInputChange}>
                                                    <option value="">Seleziona</option>
                                                    <option value="Colore 1">Colore 1</option>
                                                    <option value="...">...</option>
                                                </FormSelect>
                                            </div>
                                            <div className="col-24 col-md-12">
                                                <FormSelect type="text" name="colore[colore_telo]" id="colore_telo" value={this.props.stepsData[this.props.currentType].materiale_laterale.colore.colore_telo} label="Colore Telo" handleChange={this.handleInputChange}>
                                                    <option value="">Seleziona</option>
                                                    <option value="Colore 1">Colore 1</option>
                                                    <option value="...">...</option>
                                                </FormSelect>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                    </div>
                </div>
            </div>
        );
    }
}

const Toggle = ({ children, eventKey, callback }) => {
    const decoratedOnClick = useAccordionToggle(
        eventKey,
        () => callback && callback(eventKey),
    );

    return (
        <Card.Header onClick={decoratedOnClick}>
            {children}
        </Card.Header>
    );
}

export const ValidateMaterialeLaterale = (stepsData, currentStepData, currentType) => {
    let isValid = true
    let currentStep = stepsData[currentType].materiale_laterale

    if(!currentStep.materiale.materiale || !currentStep.colore.colore_telo) {
        isValid = false
    }

    return isValid;
}

export const ValidateMaterialeLateraleAccordion = (stepsData, currentStepData, currentType) => {
    let isValid = [true, true]
    let currentStep = stepsData[currentType].materiale_laterale

    if(!currentStep.materiale.materiale) {
        isValid[0] = false
    }

    if(!currentStep.colore.colore_telo) {
        isValid[1] = false
    }

    return isValid;
}

export default withRouter(MaterialeLaterale)