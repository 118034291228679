import React from 'react';
import { Link, useRouteMatch } from "react-router-dom";

import moment from 'moment';

const NavItem = (props) => {
    let { url } = useRouteMatch();

    url = url.replace('/riepilogo', '')

    const isEmpty = (element) => {
        if (element) {
            if (typeof element==='string') {
                if (!element) {
                    return true
                }
            }
            else if (typeof element==='object' || element instanceof Array) {
                let emptyCounter = 0
                let elementsCount = 0

                if (typeof element==='object') {
                    elementsCount = Object.keys(element).length
                }
                else {
                    elementsCount = element.length
                }

                for (var key in element) {
                    if (isEmpty(element[key])) {
                        emptyCounter++
                    }
                }

                if (emptyCounter===elementsCount) {
                    return true
                }
            }
            else {
                return true
            }
        }
        else {
            return true
        }

        return false
    }

    if(props.currentStep.id === props.steps[props.step].id) {
        if (props.currentType===props.type) {
            return (
                <li className="navigation-nav__item">
                    <strong className="active">{props.steps[props.step].name}</strong>
                </li>
            )
        }
        else {
            if (!isEmpty(props.stepsData[props.steps[props.step].id])) {
                let type = ''
                if (props.type) {
                    type = props.type + '/'
                }
    
                return (
                    <li className="navigation-nav__item">
                        <Link to={`${url}/${type}${props.steps[props.step].slug}`}>
                            <LinkText step={props.steps[props.step]} stepsData={props.stepsData} />
                        </Link>
                    </li>
                )
            }
            else {
                return null
            }
        }
    }
    else if(props.stepsData[props.step]) {
        if (!isEmpty(props.stepsData[props.steps[props.step].id])) {
            let type = ''
            if (props.type) {
                type = props.type + '/'
            }

            return (
                <li className="navigation-nav__item">
                    <Link to={`${url}/${type}${props.steps[props.step].slug}`}>
                        <LinkText step={props.steps[props.step]} stepsData={props.stepsData} />
                    </Link>
                </li>
            )
        }
        else {
            return null
        }
    }
    else{
        return null
    }
}

const LinkText = (props) => {
    let stepData = props.stepsData[props.step.id]

    if (props.step.id==='dati_cliente') {
        const getDatiMezzo = () => {
            if (stepData.dati_mezzo && (stepData.dati_mezzo.targa || stepData.dati_mezzo.marca || stepData.dati_mezzo.modello || stepData.dati_mezzo.anno)) {
                let content = '<span class="subtitle">Dati Mezzo:</span>';

                if (stepData.dati_mezzo.targa) {
                    content += 'Targa: ' + stepData.dati_mezzo.targa + '<br/>'
                }

                if (stepData.dati_mezzo.marca) {
                    content += 'Marca: ' + stepData.dati_mezzo.marca + '<br/>'
                }

                if (stepData.dati_mezzo.modello) {
                    content += 'Modello: ' + stepData.dati_mezzo.modello + '<br/>'
                }

                if (stepData.dati_mezzo.anno) {
                    content += 'Anno: ' + stepData.dati_mezzo.anno + '<br/>'
                }

                return content
            }
            else {
                return null
            }
        }

        const getDataConsegna = () => {
            if (stepData.dati_lavorazione.data_consegna) {
                return 'Data Consegna: ' + moment(stepData.dati_lavorazione.data_consegna).format("DD/MM/YYYY") + '<br/>'
            }
            else {
                return null
            }
        }

        return (
            <div>
                <span className="item-title">{props.step.name}:{' '}</span>
                <span className="item-subtitle">Dati Principali:</span>
                Azienda: {stepData.dati_principali.azienda}<br/>
                Referente: {stepData.dati_principali.azienda}<br/>
                Telefono: {stepData.dati_principali.azienda}<br/>
                <span dangerouslySetInnerHTML={{__html: getDatiMezzo()}}/>
                <span className="item-subtitle">Dati Lavorazione:</span>
                Montaggio: {stepData.dati_lavorazione.montaggio}<br/>
                <span dangerouslySetInnerHTML={{__html: getDataConsegna()}}/>
            </div>
        )
    }
    else if (props.step.id==='caratteristiche_teli') {
        return (
            <div>
                <span className="item-title">{props.step.name}:{' '}</span>
                <span className="item-subtitle">Teloni:</span>
                Quantità: {stepData.teloni.quantita}<br/>
                Posizione: {stepData.teloni.posizione}<br/>
                <span className="item-subtitle">Stampa:</span>
                Stampa Logo: {stepData.stampa.logo}<br/>
                Note: {stepData.stampa.logo_note}<br/>
            </div>
        )
    }
    else if (props.step.id==='materiale_laterale') {
        const getTipologia = () => {
            if (stepData.materiale.tipologia) {
                return 'Ignifugo: Sì<br/>'
            }
            else {
                return null
            }
        }
        const getColoreFascia = () => {
            if (stepData.colore.colore_fascia) {
                return 'Fascia: ' + stepData.colore.colore_fascia + '<br/>'
            }
            else {
                return null
            }
        }

        return (
            <div>
                <span className="item-title">{props.step.name}:{' '}</span>
                <span className="item-subtitle">Materiale:</span>
                Materiale: {stepData.materiale.materiale}<br/>
                <span dangerouslySetInnerHTML={{__html: getTipologia()}}/>
                <span className="item-subtitle">Opzione Colore:</span>
                <span dangerouslySetInnerHTML={{__html: getColoreFascia()}}/>
                Telo: {stepData.colore.colore_telo}<br/>
            </div>
        )
    }
    else if (props.step.id==='misure_laterale') {
        const getCarrelli = () => {
            if (stepData.carrelli.carrelli_cr1 || stepData.carrelli.carrelli_cr1) {
                let content = '<span class="subtitle">Dati Mezzo:</span>';

                content += 'CR1: ' + stepData.carrelli.carrelli_cr1 + '<br/>'
                content += 'CR2: ' + stepData.carrelli.carrelli_cr2 + '<br/>'

                return content
            }
            else {
                return null
            }
        }
        const getCricchetti = () => {
            if (stepData.cricchetti.passo || stepData.cricchetti.distanza_c1 || stepData.cricchetti.distanza_c2) {
                let content = '<span class="subtitle">Cricchetti:</span>';

                content += 'Passo: ' + stepData.cricchetti.passo + '<br/>'
                content += 'C1: ' + stepData.cricchetti.distanza_c1 + '<br/>'
                content += 'C2: ' + stepData.cricchetti.distanza_c2 + '<br/>'

                return content
            }
            else {
                return null
            }
        }

        return (
            <div>
                <span className="item-title">{props.step.name}:{' '}</span>
                <span className="item-subtitle">Altezza:</span>
                H1: {stepData.altezza.h1}<br/>
                H2: {stepData.altezza.h2}<br/>
                H3: {stepData.altezza.h3}<br/>
                H4: {stepData.altezza.h4}<br/>
                <span className="item-subtitle">Numero Piantoni:</span>
                Numero Piantoni: {stepData.numero_piantoni.numero_piantoni}<br/>
                <span className="item-subtitle">Interasse Piantone:</span>
                1: {stepData.interasse_piantone[0]}<br/>
                2: {stepData.interasse_piantone[1]}<br/>
                3: {stepData.interasse_piantone[2]}<br/>
                4: {stepData.interasse_piantone[3]}<br/>
                5: {stepData.interasse_piantone[4]}<br/>
                <span className="item-subtitle">Rinforzi:</span>
                RA: {stepData.rinforzi.ra}<br/>
                RP: {stepData.rinforzi.rp}<br/>
                <span dangerouslySetInnerHTML={{__html: getCarrelli()}}/>
                <span dangerouslySetInnerHTML={{__html: getCricchetti()}}/>
            </div>
        )
    }
    else if (props.step.id==='cricchetti') {
        if(stepData.tipologia_cricchetti[0]==='Recupera esistenti') {
            return (
                <div>
                    <span className="item-title">{props.step.name}:{' '}</span>
                    {stepData.numero_cricchetti} x{' '}
                    {stepData.tipologia_cricchetti[0]} ({stepData.tipologia_cricchetti[1]})
                    {stepData.tipologia_cricchetti[1]==='Sì' ? ' EN 12641-2 XL' : ''}
                </div>
            )
        }
        else {
            const getColor = () => {
                if (stepData.colore_cricchetti[0]) {
                    return <span>
                        {stepData.colore_cricchetti[0]} <span className="color" data-hex={stepData.colore_cricchetti[1]}></span>
                    </span>
                }
                else {
                    return null
                }
            } 

            return (
                <div>
                    <span className="item-title">{props.step.name}:{' '}</span>
                    {stepData.numero_cricchetti} x{' '}
                    {stepData.tipologia_cricchetti[0]} 
                    {stepData.tipologia_cricchetti[1]==='Sì' ? ' EN 12641-2 XL' : ''}
                    {getColor()}
                </div>
            )
        }
    }
    else if (props.step.id==='tipologia_cinghie') {
        return (
            <div>
                <span className="item-title">{props.step.name}:{' '}</span>
                {stepData.numero_cinghie_orizzontali} Cinghie Oriz.
                {stepData.cinghie_verticali_en==='Sì' ? ' / Cinghie Verticali 2300 Kn  EN 12641-2 XL' : ''}
                {stepData.cinghie_oblique_xl==='Sì' ? ' / Cinghie oblique XL' : ''}
            </div>
        )
    }
    else if (props.step.id==='reflex') {
        const getColor = () => {
            if (stepData.reflex[0]!=='Senza Reflex') {
                return <span className="color" data-hex={stepData.reflex[1]}></span>
            }
            else {
                return null
            }
        } 

        return (
            <div>
                <span className="item-title">{props.step.name}:{' '}</span>
                {stepData.reflex[0]} {getColor()}
            </div>
        )
    }
    else if (props.step.id==='ganci_sponda') {
        return (
            <div>
                <span className="item-title">{props.step.name}:{' '}</span>
                <span className="item-subtitle">Piantone 1:</span>
                Numero Ganci: {stepData.piantone_1.numero_ganci}<br/>
                Passo Ganci: {stepData.piantone_1.passo_ganci}<br/>
                Distanza G1: {stepData.piantone_1.distanza_g1}<br/>
                Distanza G2: {stepData.piantone_1.distanza_g2}
                <span className="item-subtitle">Piantone 2:</span>
                Numero Ganci: {stepData.piantone_2.numero_ganci}<br/>
                Passo Ganci: {stepData.piantone_2.passo_ganci}<br/>
                Distanza G1: {stepData.piantone_2.distanza_g1}<br/>
                Distanza G2: {stepData.piantone_2.distanza_g2}
                <span className="item-subtitle">Piantone 3:</span>
                Numero Ganci: {stepData.piantone_3.numero_ganci}<br/>
                Passo Ganci: {stepData.piantone_3.passo_ganci}<br/>
                Distanza G1: {stepData.piantone_3.distanza_g1}<br/>
                Distanza G2: {stepData.piantone_3.distanza_g2}
                <span className="item-subtitle">Piantone 4:</span>
                Numero Ganci: {stepData.piantone_4.numero_ganci}<br/>
                Passo Ganci: {stepData.piantone_4.passo_ganci}<br/>
                Distanza G1: {stepData.piantone_4.distanza_g1}<br/>
                Distanza G2: {stepData.piantone_4.distanza_g2}
            </div>
        )
    }
    else if (props.step.id==='distanza_piantoni') {
        return (
            <div>
                <span className="item-title">{props.step.name}:{' '}</span>
                Passo anteriore: {stepData.passo_anteriore}<br/>
                Passo posteriore: {stepData.passo_anteriore}<br/>
            </div>
        )
    }
    else if (props.step.id==='tipologia_carrelli') {
        return (
            <div>
                <span className="item-title">{props.step.name}:{' '}</span>
                {stepData.numero_carrelli} x {' '}
                {stepData.tipologia_carrelli}
                {stepData.tipologia_carrelli_esistenti ? ' (' + stepData.tipologia_carrelli_esistenti + ')' : ''}
            </div>
        )
    }
    else if (props.step.id==='tenditelo') {
        return (
            <div>
                <span className="item-title">{props.step.name}:{' '}</span>
                {stepData.tenditelo}{' '}
                {stepData.tipologia}{' '}
                {stepData.posizione}
            </div>
        )
    }
    else if (props.step.id==='rete_antivandalismo') {
        const getAltezza = () => {
            if (stepData.altezza) {
                return 'Altezza: ' + stepData.altezza
            }
            else {
                return null
            }
        } 

        return (
            <div>
                <span className="item-title">{props.step.name}:{' '}</span>
                {stepData.rete_antivandalismo}{' '}
                {stepData.griglia}<br/>
                <span dangerouslySetInnerHTML={{__html: getAltezza()}}/>
            </div>
        )
    }
    else if (props.step.id==='dimensioni_tetto') {
        return (
            <div>
                <span className="item-title">{props.step.name}:{' '}</span>
                {stepData.lunghezza}x
                {stepData.larghezza}
            </div>
        )
    }
    else if (props.step.id==='traversi') {
        return (
            <div>
                <span className="item-title">{props.step.name}:{' '}</span>
                N° Traversi: {stepData.numero_traversi}<br/>
                <span className="item-subtitle">Passo:</span>
                A: {stepData.passo_traversi.a}<br/>
                B: {stepData.passo_traversi.b}<br/>
                C: {stepData.passo_traversi.c}<br/>
                D: {stepData.passo_traversi.d}
            </div>
        )
    }
    else if (props.step.id==='correnti') {
        return (
            <div>
                <span className="item-title">{props.step.name}:{' '}</span>
                N° Correnti: {stepData.numero_correnti}<br/>
                <span className="item-subtitle">Passo:</span>
                A: {stepData.passo_correnti.a}<br/>
                B: {stepData.passo_correnti.b}<br/>
                C: {stepData.passo_correnti.c}<br/>
                D: {stepData.passo_correnti.d}<br/>
                E: {stepData.passo_correnti.e}<br/>
                F: {stepData.passo_correnti.f}
            </div>
        )
    }
    else if (props.step.id==='fronte_tetto') {
        return (
            <div>
                <span className="item-title">{props.step.name}:{' '}</span>
                {stepData.altezza_frontale}m
            </div>
        )
    }
    else if (props.step.id==='archi') {
        return (
            <div>
                <span className="item-title">{props.step.name}:{' '}</span>
                N° Archi: {stepData.numero_archi}<br/>
                Passo Ganci: {stepData.passo_archi}<br/>
                Dist. 1° Arco: {stepData.distanza_a1}<br/>
                Dist. ultimo Arco: {stepData.distanza_a2}
            </div>
        )
    }
    else if (props.step.id==='spalla_tetto') {
        return (
            <div>
                <span className="item-title">{props.step.name}:{' '}</span>
                D: {stepData.d}<br/>
                E: {stepData.e}<br/>
                F: {stepData.f}<br/>
                G: {stepData.g}
            </div>
        )
    }
    else if (props.step.id==='occhielli') {
        return (
            <div>
                <span className="item-title">{props.step.name}:{' '}</span>
                Numero: {stepData.numero}<br/>
                Disposizione: {stepData.disposizione}<br/>
                Quota: {stepData.quota}<br/>
                Interasse: {stepData.interasse}
            </div>
        )
    }
    else if (props.step.id==='retrotelo') {
        return (
            <div>
                <span className="item-title">{props.step.name}:{' '}</span>
                LA: {stepData.la}<br/>
                H: {stepData.h}
            </div>
        )
    }
    else if (props.step.id==='retroporte_a') {
        return (
            <div>
                <span className="item-title">{props.step.name}:{' '}</span>
                LA: {stepData.la}<br/>
                L1: {stepData.l1}<br/>
                L2: {stepData.l2}<br/>
                L3: {stepData.l3}<br/>
                H: {stepData.h}
            </div>
        )
    }
    else if (props.step.id==='retroporte_b') {
        return (
            <div>
                <span className="item-title">{props.step.name}:{' '}</span>
                L1: {stepData.l1}<br/>
                L2: {stepData.l2}<br/>
                H: {stepData.h}
            </div>
        )
    }
    else if (props.step.id==='opzioni') {
        const getLabel = (option) => {
            let label = ''

            if (option==='opt_1') {
                label = 'Cavallotto Rett. Base Rett. 2/F Alto'
            }
            else if (option==='opt_2') {
                label = 'Cavallotto Rett. Base Rett. 2/F Basso'
            }
            else if (option==='opt_3') {
                label = 'Cavallotto Sagomato Pipa'
            }
            else if (option==='opt_4') {
                label = 'Cavallotto Tourniquet'
            }
            else if (option==='opt_5') {
                label = 'Cavetto Tir. D.6 mm Da 21 mt'
            }
            else if (option==='opt_6') {
                label = 'Cavetto Tir. D.6 mm Da 22,50 mt'
            }
            else if (option==='opt_7') {
                label = 'Cavetto Tir. D.6 mm DA 28 mt'
            }
            else if (option==='opt_8') {
                label = 'Cavetto Tir. D.6 mm Da 34 mt'
            }
            else if (option==='opt_9') {
                label = 'Cavetto Tir. D.6 mm Da 36 mt'
            }
            else if (option==='opt_10') {
                label = 'Cavetto Tir. D.8 mm Da 38 mt'
            }
            else if (option==='opt_11') {
                label = 'Cavetto Tir. D.8 mm Da 40 mt'
            }
            else if (option==='opt_12') {
                label = 'Chiusura Campagnola Completa'
            }
            else if (option==='opt_13') {
                label = 'Cinghia Tir'
            }
            else if (option==='opt_14') {
                label = 'Cinturino C21 Ns Produzione'
            }
            else if (option==='opt_15') {
                label = 'Striscia in PVC Per Tetto Copri Scopri'
            }
            else if (option==='opt_16') {
                label = 'Coprigiunto In Alluminio'
            }
            else if (option==='opt_17') {
                label = 'Corda Treccia D.4 mm'
            }
            else if (option==='opt_18') {
                label = 'Corda Treccia D.5 mm'
            }
            else if (option==='opt_19') {
                label = 'Corda Treccia D.8 mm'
            }
            else if (option==='opt_20') {
                label = 'Elastico D.8 mm Colore......'
            }
            else if (option==='opt_21') {
                label = 'Gancio Becco D’Aquila'
            }
            else if (option==='opt_22') {
                label = 'Gancio Cestello'
            }
            else if (option==='opt_23') {
                label = 'Gancio Cobra'
            }
            else if (option==='opt_24') {
                label = 'Gancio Nylon Tondo'
            }
            else if (option==='opt_25') {
                label = 'Gancio S Aperto Fe/Zn'
            }
            else if (option==='opt_26') {
                label = 'Gancio S Chiuso Fe/Zn'
            }
            else if (option==='opt_27') {
                label = 'Gancio S Larga Chiuso Nylon'
            }
            else if (option==='opt_28') {
                label = 'Gancio S Larga Nylon'
            }
            else if (option==='opt_29') {
                label = 'Gancio Zig-Zag Doppio Nylon Bianco'
            }
            else if (option==='opt_30') {
                label = 'Gancio Zig-Zag Doppio Nylon Nero'
            }
            else if (option==='opt_31') {
                label = 'Gancio Zig-Zag Fe/Zn Grande'
            }
            else if (option==='opt_32') {
                label = 'Gancio Zig-Zag Fe/Zn Piccolo'
            }
            else if (option==='opt_33') {
                label = 'Gancio Zig-Zag Nylon Chiuso Bianco'
            }
            else if (option==='opt_34') {
                label = 'Gancio Zig-Zag Nylon Chiuso Nero'
            }
            else if (option==='opt_35') {
                label = 'Occhiello Ovale Ferro'
            }
            else if (option==='opt_36') {
                label = 'Occhiello VL50'
            }
            else if (option==='opt_37') {
                label = 'Occhiello VL80'
            }
            else if (option==='opt_38') {
                label = 'Occhiello VL80 Nero'
            }
            else if (option==='opt_39') {
                label = 'Occhiello VL150'
            }
            else if (option==='opt_40') {
                label = 'Piatto PVC'
            }
            else if (option==='opt_41') {
                label = 'Profilo Alluminio 20x40 C/Ogiva'
            }
            else if (option==='opt_42') {
                label = 'Stecca Alluminio'
            }
            else if (option==='opt_43') {
                label = 'Tubetto Desmopan'
            }

            return label
        }

        return (
            <div>
                <span className="item-title">{props.step.name}:{' '}</span>
                {Object.entries(stepData).map((value, i) => {
                    if (typeof value[1]==='string' && value[1]){
                        return (
                            <div key={i}>{getLabel(value[0])}: {value[1]}</div>
                        )
                    }
                    else {
                        return (
                            null
                        )
                    }
                })}
            </div>
        )
    }
    else if (props.step.id==='opzioni_prontocar') {
        const getLabel = (option) => {
            let label = ''

            if (option==='opt_1') {
                label = 'Cinghia Spalmata P.Car'
            }
            else if (option==='opt_2') {
                label = 'Cricchetto Inox C/Cinghia G. Tranc'
            }
            else if (option==='opt_3') {
                label = 'Cricchetto Inox C/Cinghia C/Sicura'
            }
            else if (option==='opt_4') {
                label = 'Cricchetto Plastica Rosso C/Cinghia'
            }
            else if (option==='opt_5') {
                label = 'Cricchetto P.Car Inox Diretto'
            }
            else if (option==='opt_6') {
                label = 'Cinghia Ricambio Cricchetto P.Car'
            }
            else if (option==='opt_7') {
                label = 'Barra Tenditelo Alluminio D.27 mm'
            }
            else if (option==='opt_8') {
                label = 'Barra Tenditelo Alluminio D.35 mm'
            }
            else if (option==='opt_9') {
                label = 'Perno Inferiore D.27 mm'
            }
            else if (option==='opt_10') {
                label = 'Perno Superiore D.27 mm'
            }
            else if (option==='opt_11') {
                label = 'Perno Inferiore D.35 mm'
            }
            else if (option==='opt_12') {
                label = 'Perno Superiore D.35 mm'
            }
            else if (option==='opt_13') {
                label = 'Perno Adattatore D.35 mm x Asta D. 27 mm'
            }
            else if (option==='opt_14') {
                label = 'Profilo Tensotel Tipo Nuovo'
            }
            else if (option==='opt_15') {
                label = 'Profilo Tensotel Tipo Vecchio'
            }
            else if (option==='opt_16') {
                label = 'Profilo Tensotel Tipo Piccolo'
            }
            else if (option==='opt_17') {
                label = 'Profilo Z Zincato'
            }
            else if (option==='opt_18') {
                label = 'Tubetto in PVC D.10 mm'
            }
            else if (option==='opt_19') {
                label = 'Tubetto in PVC D.12 mm'
            }

            return label
        }

        return (
            <div>
                <span className="item-title">{props.step.name}:{' '}</span>
                {Object.entries(stepData).map((value, i) => {
                    if (typeof value[1]==='string' && value[1]){
                        return (
                            <div key={i}>{getLabel(value[0])}: {value[1]}</div>
                        )
                    }
                    else {
                        return (
                            null
                        )
                    }
                })}
            </div>
        )
    }
    else if (props.step.id==='opzioni_rollfix_planeroller') {
        const getLabel = (option) => {
            let label = ''

            if (option==='opt_1') {
                label = 'Reflex Bianco'
            }
            else if (option==='opt_2') {
                label = 'Reflex Giallo'
            }
            else if (option==='opt_3') {
                label = 'Angolare 40x40x2 Alluminio'
            }
            else if (option==='opt_4') {
                label = 'Bordino D.10 mm'
            }
            else if (option==='opt_5') {
                label = 'Bordino D.7,5 mm'
            }
            else if (option==='opt_6') {
                label = 'Bordino D.11 mm'
            }
            else if (option==='opt_7') {
                label = 'Bordino D.13 mm'
            }
            else if (option==='opt_8') {
                label = 'Calotta In PVC D.43 mm'
            }
            else if (option==='opt_9') {
                label = 'Molla Filo 2 mm D.43 mm L.600 mm'
            }
            else if (option==='opt_10') {
                label = 'Molla Inox Da 1500 mm'
            }
            else if (option==='opt_11') {
                label = 'Profilo Doppia Ogiva'
            }
            else if (option==='opt_12') {
                label = 'Supporto Per Molla D.43 mm'
            }
            else if (option==='opt_13') {
                label = 'Tappi Per Profilo'
            }
            else if (option==='opt_14') {
                label = 'Tubo C/Ogiva D.43 mm L.2550 mm'
            }
            else if (option==='opt_15') {
                label = 'Statistica € + C.F. = Costo'
            }

            return label
        }

        return (
            <div>
                <span className="item-title">{props.step.name}:{' '}</span>
                {Object.entries(stepData).map((value, i) => {
                    if (typeof value[1]==='string' && value[1]){
                        return (
                            <div key={i}>{getLabel(value[0])}: {value[1]}</div>
                        )
                    }
                    else {
                        return (
                            null
                        )
                    }
                })}
            </div>
        )
    }
    else if (props.step.id==='note') {
        return (
            <div>
                <span className="item-title">{props.step.name}:{' '}</span>
                <span className="item-subtitle">Note Aggiuntive:</span>
                {stepData.note_aggiuntive}
                <span className="item-subtitle">Altro:</span>
                {stepData.altro}
            </div>
        )
    }
    else {
        return (
            <div>
                <span className="item-title">{props.step.name}:{' '}</span>
                {Object.entries(stepData).map((value, i) => {
                    if (typeof value[1]==='string'){
                        return (
                            value[1] + ' '
                        )
                    }
                    else {
                        let returnStr = ''

                        for (let item in value[1]) {
                            returnStr += value[1][item] + ' '
                        }

                        return returnStr
                    }
                })}
            </div>
        )
    }
}

export default NavItem;