import React from 'react';
import { withRouter } from "react-router-dom";

import Image from './Image.js';
import FormInput from '../Form/Input';

class GanciSponda extends React.Component {
    constructor(props) {
        super(props);

        this.step = props.steps['ganci_sponda'];

        this.state = {
            nextStep: 'distanza_piantoni'
        }

        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount() {
        this.props.updateStep(this.step.id, this.step.name, this.state.nextStep, this.props.match.params.typeId, 0, 4)
    }

    handleInputChange = event => {
        const target = event.target;
        let value = target.getAttribute('type') === 'checkbox' ? target.checked : target.value;
        let name = target.name;

        if (name.indexOf('[')>0) {
            let newName = name.replace(/\[(\w+)]/g, '')
            let key = /\[(.*?)\]/g.exec(name)[1]

            let newValue = this.props.stepsData[this.props.currentType][this.step.id][newName]
            newValue[key] = value

            name = newName
            value = newValue
        }

        this.props.updateData(this.props.currentType, this.step.id, name, value)
    }

    updateSubstep = (choice, substep) => {
        this.props.updateStep(this.step.id, this.step.name, this.props.currentStep.next, null, substep, 4)
    }

    render() {
        if (this.props.stepsData[this.props.currentType]) {
            return (
                <div>
                    <GanciSpondaSteps currentStep={this.props.currentStep} currentType={this.props.currentType} stepsData={this.props.stepsData} handleChange={this.handleInputChange} updateData={this.props.updateData} updateStep={this.props.updateStep} />
                </div>
            )
        }
        else {
            return null
        }
    }
}

const GanciSpondaSteps = (props) => {
    if (props.currentStep.substep===0 || !props.currentStep.substep) {
        return (
            <div className="step step__ganci-sponda">
                <div className="row">
                    <div className="col-22 col-lg-20 col-xl-18 offset-1 offset-lg-2 offset-xl-3">
                        <div className="step__image">
                            <Image directory={ props.currentStep.id } name="piantone_1" />
                        </div>
                        <div className="step__form">
                            <div className="step__title">Piantone 1</div>
                            <div className="row justify-content-center">
                                <div className="col-24 col-md-11 col-lg-10 col-xl-8">
                                    <FormInput type="number" name="piantone_1[numero_ganci]" id="piantone_1_numero_ganci" value={props.stepsData[props.currentType].ganci_sponda.piantone_1.numero_ganci} label="N° Ganci" handleChange={props.handleChange} />
                                </div>
                                <div className="col-24 col-md-11 col-lg-10 col-xl-8">
                                    <FormInput type="number" name="piantone_1[passo_ganci]" id="piantone_1_passo_ganci" value={props.stepsData[props.currentType].ganci_sponda.piantone_1.passo_ganci} label="Passo Ganci" handleChange={props.handleChange} />
                                </div>
                                <div className="w-100 d0none d-xl-flex"></div>
                                <div className="col-24 col-md-11 col-lg-10 col-xl-8">
                                    <FormInput type="number" name="piantone_1[distanza_g1]" id="piantone_1_distanza_g1" value={props.stepsData[props.currentType].ganci_sponda.piantone_1.distanza_g1} label="Dist. G1" handleChange={props.handleChange} note="Dist. 1° gancio" />
                                </div>
                                <div className="col-24 col-md-11 col-lg-10 col-xl-8">
                                    <FormInput type="number" name="piantone_1[distanza_g2]" id="piantone_1_distanza_g2" value={props.stepsData[props.currentType].ganci_sponda.piantone_1.distanza_g2} label="Dist. G2" handleChange={props.handleChange} note="Dist. ultimo gancio" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    else if (props.currentStep.substep===1) {
        return (
            <div className="step step__ganci-sponda">
                <div className="row">
                    <div className="col-22 col-lg-20 col-xl-18 offset-1 offset-lg-2 offset-xl-3">
                        <div className="step__image">
                            <Image directory={ props.currentStep.id } name="piantone_1" />
                        </div>
                        <div className="step__form">
                            <div className="step__title">Piantone 2</div>
                            <div className="row justify-content-center">
                                <div className="col-24 col-md-11 col-lg-10 col-xl-8">
                                    <FormInput type="number" name="piantone_2[numero_ganci]" id="piantone_2_numero_ganci" value={props.stepsData[props.currentType].ganci_sponda.piantone_2.numero_ganci} label="N° Ganci" handleChange={props.handleChange} />
                                </div>
                                <div className="col-24 col-md-11 col-lg-10 col-xl-8">
                                    <FormInput type="number" name="piantone_2[passo_ganci]" id="piantone_2_passo_ganci" value={props.stepsData[props.currentType].ganci_sponda.piantone_2.passo_ganci} label="Passo Ganci" handleChange={props.handleChange} />
                                </div>
                                <div className="w-100 d0none d-xl-flex"></div>
                                <div className="col-24 col-md-11 col-lg-10 col-xl-8">
                                    <FormInput type="number" name="piantone_2[distanza_g1]" id="piantone_2_distanza_g1" value={props.stepsData[props.currentType].ganci_sponda.piantone_2.distanza_g1} label="Dist. G1" handleChange={props.handleChange} note="Dist. 1° gancio" />
                                </div>
                                <div className="col-24 col-md-11 col-lg-10 col-xl-8">
                                    <FormInput type="number" name="piantone_2[distanza_g2]" id="piantone_2_distanza_g2" value={props.stepsData[props.currentType].ganci_sponda.piantone_2.distanza_g2} label="Dist. G2" handleChange={props.handleChange} note="Dist. ultimo gancio" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    else if (props.currentStep.substep===2) {
        return (
            <div className="step step__ganci-sponda">
                <div className="row">
                    <div className="col-22 col-lg-20 col-xl-18 offset-1 offset-lg-2 offset-xl-3">
                        <div className="step__image">
                            <Image directory={ props.currentStep.id } name="piantone_2" />
                        </div>
                        <div className="step__form">
                            <div className="step__title">Piantone 3</div>
                            <div className="row justify-content-center">
                                <div className="col-24 col-md-11 col-lg-10 col-xl-8">
                                    <FormInput type="number" name="piantone_3[numero_ganci]" id="piantone_3_numero_ganci" value={props.stepsData[props.currentType].ganci_sponda.piantone_3.numero_ganci} label="N° Ganci" handleChange={props.handleChange} />
                                </div>
                                <div className="col-24 col-md-11 col-lg-10 col-xl-8">
                                    <FormInput type="number" name="piantone_3[passo_ganci]" id="piantone_3_passo_ganci" value={props.stepsData[props.currentType].ganci_sponda.piantone_3.passo_ganci} label="Passo Ganci" handleChange={props.handleChange} />
                                </div>
                                <div className="w-100 d0none d-xl-flex"></div>
                                <div className="col-24 col-md-11 col-lg-10 col-xl-8">
                                    <FormInput type="number" name="piantone_3[distanza_g1]" id="piantone_3_distanza_g1" value={props.stepsData[props.currentType].ganci_sponda.piantone_3.distanza_g1} label="Dist. G1" handleChange={props.handleChange} note="Dist. 1° gancio" />
                                </div>
                                <div className="col-24 col-md-11 col-lg-10 col-xl-8">
                                    <FormInput type="number" name="piantone_3[distanza_g2]" id="piantone_3_distanza_g2" value={props.stepsData[props.currentType].ganci_sponda.piantone_3.distanza_g2} label="Dist. G2" handleChange={props.handleChange} note="Dist. ultimo gancio" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    else if (props.currentStep.substep===3) {
        return (
            <div className="step step__ganci-sponda">
                <div className="row">
                    <div className="col-22 col-lg-20 col-xl-18 offset-1 offset-lg-2 offset-xl-3">
                        <div className="step__image">
                            <Image directory={ props.currentStep.id } name="piantone_2" />
                        </div>
                        <div className="step__form">
                            <div className="step__title">Piantone 4</div>
                            <div className="row justify-content-center">
                                <div className="col-24 col-md-11 col-lg-10 col-xl-8">
                                    <FormInput type="number" name="piantone_4[numero_ganci]" id="piantone_4_numero_ganci" value={props.stepsData[props.currentType].ganci_sponda.piantone_4.numero_ganci} label="N° Ganci" handleChange={props.handleChange} />
                                </div>
                                <div className="col-24 col-md-11 col-lg-10 col-xl-8">
                                    <FormInput type="number" name="piantone_4[passo_ganci]" id="piantone_4_passo_ganci" value={props.stepsData[props.currentType].ganci_sponda.piantone_4.passo_ganci} label="Passo Ganci" handleChange={props.handleChange} />
                                </div>
                                <div className="w-100 d0none d-xl-flex"></div>
                                <div className="col-24 col-md-11 col-lg-10 col-xl-8">
                                    <FormInput type="number" name="piantone_4[distanza_g1]" id="piantone_4_distanza_g1" value={props.stepsData[props.currentType].ganci_sponda.piantone_4.distanza_g1} label="Dist. G1" handleChange={props.handleChange} note="Dist. 1° gancio" />
                                </div>
                                <div className="col-24 col-md-11 col-lg-10 col-xl-8">
                                    <FormInput type="number" name="piantone_4[distanza_g2]" id="piantone_4_distanza_g2" value={props.stepsData[props.currentType].ganci_sponda.piantone_4.distanza_g2} label="Dist. G2" handleChange={props.handleChange} note="Dist. ultimo gancio" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    else {
        return null
    }
}

export const ValidateGanciSponda = (stepsData, currentStepData, currentType) => {
    let isValid = true
    let currentStep = stepsData[currentType].ganci_sponda

    if (currentStepData.substep===0) {
        if (!currentStep.piantone_1.numero_ganci || !currentStep.piantone_1.passo_ganci || !currentStep.piantone_1.distanza_g1 || !currentStep.piantone_1.distanza_g2) {
            isValid = false
        }
    }
    else if (currentStepData.substep===1) {
        if (!currentStep.piantone_2.numero_ganci || !currentStep.piantone_2.passo_ganci || !currentStep.piantone_2.distanza_g1 || !currentStep.piantone_2.distanza_g2) {
            isValid = false
        }
    }
    else if (currentStepData.substep===2) {
        if (!currentStep.piantone_3.numero_ganci || !currentStep.piantone_3.passo_ganci || !currentStep.piantone_3.distanza_g1 || !currentStep.piantone_3.distanza_g2) {
            isValid = false
        }
    }
    else if (currentStepData.substep===3) {
        if (!currentStep.piantone_4.numero_ganci || !currentStep.piantone_4.passo_ganci || !currentStep.piantone_4.distanza_g1 || !currentStep.piantone_4.distanza_g2) {
            isValid = false
        }
    }

    return isValid;
}

export default withRouter(GanciSponda)
