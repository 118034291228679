import React from 'react';
import SimpleStorage from "react-simple-storage";
import { withRouter } from "react-router-dom";

import Accordion from 'react-bootstrap/Accordion'
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';
import Card from 'react-bootstrap/Card'

import Image from './Image.js';
import FormInput from '../Form/Input';
import FormRadio from '../Form/Radio';

class MisureLaterale extends React.Component {
    constructor(props) {
        super(props);

        this.step = props.steps['misure_laterale'];

        this.state = {
            nextStep: 'cricchetti',
            accordionActiveElement: 0
        }

        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount() {
        this.props.updateStep(this.step.id, this.step.name, this.state.nextStep, this.props.match.params.typeId)
    }

    handleInputChange = event => {
        const target = event.target;
        let value = target.getAttribute('type') === 'checkbox' ? target.checked : target.value;
        let name = target.name;

        // if (name.indexOf('[')>0) {
        //     let newName = name.replace(/\[(\w+)]/g, '')
        //     let key = /\[(.*?)\]/g.exec(name)[1]

        //     let newValue = this.props.stepsData[this.step.id][newName]
        //     newValue[key] = value

        //     name = newName
        //     value = newValue
        // }

        this.props.updateData(this.props.currentType, this.step.id, name, value)
    }

    handleAccordionToggle = (eventKey) => {
        if (parseInt(eventKey)!==this.state.accordionActiveElement) {
            this.setState({
                accordionActiveElement: parseInt(eventKey)
            }, () => {
            })
        }
        else {
            this.setState({
                accordionActiveElement: null
            }, () => {
            })
        }
    }

    cardClass = (eventKey) => {
        let htmlClass = 'card'

        if (parseInt(eventKey)===parseInt(this.state.accordionActiveElement)) {
            htmlClass += ' show'
        }

        if (this.props.stepsAccordionValidation[this.step.id] && this.props.stepsAccordionValidation[this.step.id][eventKey]) {
            htmlClass += ' valid'
        }

        if (eventKey===4 && !(this.props.stepsData[this.props.currentType].teli_laterali.teli_laterali[0]==='Francese' || this.props.stepsData[this.props.currentType].teli_laterali.teli_laterali[1]==='Scorrevole')) {
            htmlClass += ' d-none'
        }

        if (eventKey===5 && this.props.stepsData[this.props.currentType].teli_laterali.teli_laterali[0]!=='Francese') {
            htmlClass += ' d-none'
        }

        return htmlClass;
    }

    updateNextStep = (teli) => {
        let nextStep = 'cricchetti';

        if (this.props.stepsData[this.props.currentType].teli_laterali.teli_laterali[0]==='Spondato') {
            nextStep = 'elastico';
        }

        this.setState({ 
            nextStep: nextStep
        }, () => {
            this.props.updateStep(this.step.id, this.step.name, this.state.nextStep)
        })
    }

    render() {
        return (
            <div className="step step__misure-laterale">
                <SimpleStorage parent={this} prefix={"Configurator"} onParentStateHydrated={() => {
                    this.updateNextStep()
                }} />
                <div className="row justify-content-center">
                    <div className="col-24 col-lg-20">
                        <div className="text-center">
                            <Image directory="mezzo" name="mezzo" changeType={true} currentType={this.props.stepsData.tipologia_mezzo.tipo_mezzo} currentSubtype={this.props.currentType} stepsData={this.props.stepsData} changeLaterali={true} currentLaterali={this.props.stepsData[this.props.currentType].teli_laterali.teli_laterali[0]} />
                        </div>
                        <Accordion defaultActiveKey="0">
                            <Card bsPrefix={this.cardClass(0)}>
                                <Toggle eventKey="0" callback={this.handleAccordionToggle}>
                                    <span>Altezza</span>
                                </Toggle>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body>
                                        <div className="row">
                                            <div className="col-24 col-md-6 col-xxl-4">
                                                <FormInput type="number" name="altezza[h1]" id="h1" value={this.props.stepsData[this.props.currentType].misure_laterale.altezza.h1} label="H1" handleChange={this.handleInputChange} />
                                            </div>
                                            <div className="col-24 col-md-6 col-xxl-4">
                                                <FormInput type="number" name="altezza[h2]" id="h2" value={this.props.stepsData[this.props.currentType].misure_laterale.altezza.h2} label="H2" handleChange={this.handleInputChange} />
                                            </div>
                                            <div className="col-24 col-md-6 col-xxl-4">
                                                <FormInput type="number" name="altezza[h3]" id="h3" value={this.props.stepsData[this.props.currentType].misure_laterale.altezza.h3} label="H3" handleChange={this.handleInputChange} />
                                            </div>
                                            <div className="col-24 col-md-6 col-xxl-4">
                                                <FormInput type="number" name="altezza[h4]" id="h4" value={this.props.stepsData[this.props.currentType].misure_laterale.altezza.h4} label="H4" handleChange={this.handleInputChange} />
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>

                            <Card bsPrefix={this.cardClass(1)}>
                                <Toggle eventKey="1" callback={this.handleAccordionToggle}>
                                    <span>Numero Piantoni</span>
                                </Toggle>
                                <Accordion.Collapse eventKey="1">
                                    <Card.Body>
                                    <div className="row">
                                            <div className="col-24 col-md-6 col-xxl-4">
                                                <FormRadio name="numero_piantoni[numero_piantoni]" id="numero_piantoni_0" value="1" label="1" handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].misure_laterale.numero_piantoni.numero_piantoni === '1'} />
                                            </div>
                                            <div className="col-24 col-md-6 col-xxl-4">
                                                <FormRadio name="numero_piantoni[numero_piantoni]" id="numero_piantoni_1" value="2" label="2" handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].misure_laterale.numero_piantoni.numero_piantoni === '2'} />
                                            </div>
                                            <div className="col-24 col-md-6 col-xxl-4">
                                                <FormRadio name="numero_piantoni[numero_piantoni]" id="numero_piantoni_2" value="3" label="3" handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].misure_laterale.numero_piantoni.numero_piantoni === '3'} />
                                            </div>
                                            <div className="col-24 col-md-6 col-xxl-4">
                                                <FormRadio name="numero_piantoni[numero_piantoni]" id="numero_piantoni_3" value="4" label="4" handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].misure_laterale.numero_piantoni.numero_piantoni === '4'} />
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>

                            <Card bsPrefix={this.cardClass(2)}>
                                <Toggle eventKey="2" callback={this.handleAccordionToggle}>
                                    <span>Interasse Piantone</span>
                                </Toggle>
                                <Accordion.Collapse eventKey="2">
                                    <Card.Body>
                                        <div className="row">
                                            <div className="col-24 col-md-8 col-lg-6 col-xxl-4">
                                                <FormInput type="number" name="interasse_piantone[0]" id="interasse_piantone_0" value={this.props.stepsData[this.props.currentType].misure_laterale.interasse_piantone[0]} label="Piantone 1" handleChange={this.handleInputChange} />
                                            </div>
                                            <div className="col-24 col-md-8 col-lg-6 col-xxl-4">
                                                <FormInput type="number" name="interasse_piantone[1]" id="interasse_piantone_1" value={this.props.stepsData[this.props.currentType].misure_laterale.interasse_piantone[1]} label="Piantone 2" handleChange={this.handleInputChange} />
                                            </div>
                                            <div className="col-24 col-md-8 col-lg-6 col-xxl-4">
                                                <FormInput type="number" name="interasse_piantone[2]" id="interasse_piantone_2" value={this.props.stepsData[this.props.currentType].misure_laterale.interasse_piantone[2]} label="Piantone 3" handleChange={this.handleInputChange} />
                                            </div>
                                            <div className="col-24 col-md-8 col-lg-6 col-xxl-4">
                                                <FormInput type="number" name="interasse_piantone[3]" id="interasse_piantone_3" value={this.props.stepsData[this.props.currentType].misure_laterale.interasse_piantone[3]} label="Piantone 4" handleChange={this.handleInputChange} />
                                            </div>
                                            <div className="col-24 col-md-8 col-lg-6 col-xxl-4">
                                                <FormInput type="number" name="interasse_piantone[4]" id="interasse_piantone_4" value={this.props.stepsData[this.props.currentType].misure_laterale.interasse_piantone[4]} label="Piantone 5" handleChange={this.handleInputChange} />
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>

                            <Card bsPrefix={this.cardClass(3)}>
                                <Toggle eventKey="3" callback={this.handleAccordionToggle}>
                                    <span>Rinforzi</span>
                                </Toggle>
                                <Accordion.Collapse eventKey="3">
                                    <Card.Body>
                                        <div className="row">
                                            <div className="col-24 col-md-8 col-lg-6 col-xxl-4">
                                                <FormInput type="number" name="rinforzi[ra]" id="rinforzi_ra" value={this.props.stepsData[this.props.currentType].misure_laterale.rinforzi.ra} label="RA" handleChange={this.handleInputChange} />
                                            </div>
                                            <div className="col-24 col-md-8 col-lg-6 col-xxl-4">
                                                <FormInput type="number" name="rinforzi[rp]" id="rinforzi_rp" value={this.props.stepsData[this.props.currentType].misure_laterale.rinforzi.rp} label="RP" handleChange={this.handleInputChange} />
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>

                            <Card bsPrefix={this.cardClass(4)}>
                                <Toggle eventKey="4" callback={this.handleAccordionToggle}>
                                    <span>Carrelli</span>
                                </Toggle>
                                <Accordion.Collapse eventKey="4">
                                    <Card.Body>
                                        <div className="row align-items-center">
                                            <div className="col-24 col-md-auto">
                                                Primo e<br/> ultimo carrello
                                            </div>
                                            <div className="col-24 col-md-8 col-lg-6 col-xxl-4 no-form-margin-mobile no-form-margin-desktop">
                                                <FormInput type="number" name="carrelli[cr1]" id="carrelli_cr1" value={this.props.stepsData[this.props.currentType].misure_laterale.carrelli.cr1} label="CR1" handleChange={this.handleInputChange} />
                                            </div>
                                            <div className="col-24 col-md-8 col-lg-6 col-xxl-4 no-form-margin-mobile no-form-margin-desktop">
                                                <FormInput type="number" name="carrelli[cr2]" id="carrelli_cr2" value={this.props.stepsData[this.props.currentType].misure_laterale.carrelli.cr2} label="CR2" handleChange={this.handleInputChange} />
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>

                            <Card bsPrefix={this.cardClass(5)}>
                                <Toggle eventKey="5" callback={this.handleAccordionToggle}>
                                    <span>Cricchetti</span>
                                </Toggle>
                                <Accordion.Collapse eventKey="5">
                                    <Card.Body>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="row align-items-center">
                                                    <div className="col-24 col-xxl-auto">
                                                        <span style={{display: 'inline-block', marginBottom: '15px'}}>Passo<br/> cricchetti</span>
                                                    </div>
                                                    <div className="col-24 col-xxl-8">
                                                        <FormInput type="text" name="cricchetti[passo]" id="cricchetti_passo" value={this.props.stepsData[this.props.currentType].misure_laterale.cricchetti.passo} label="" handleChange={this.handleInputChange} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="row align-items-center">
                                                    <div className="col-24 col-xxl-auto">
                                                        <span style={{display: 'inline-block', marginBottom: '15px'}}>Distanza primo<br/> ultimo cricchetto</span>
                                                    </div>
                                                    <div className="col-24 col-xxl-8">
                                                        <FormInput type="text" name="cricchetti[distanza_c1]" id="cricchetti_distanza_c1" value={this.props.stepsData[this.props.currentType].misure_laterale.cricchetti.distanza_c1} label="C1" handleChange={this.handleInputChange} />
                                                    </div>
                                                    <div className="col-24 col-xxl-8">
                                                        <FormInput type="text" name="cricchetti[distanza_c2]" id="cricchetti_distanza_c2" value={this.props.stepsData[this.props.currentType].misure_laterale.cricchetti.distanza_c2} label="C2" handleChange={this.handleInputChange} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                    </div>
                </div>
            </div>
        );
    }
}

const Toggle = ({ children, eventKey, callback }) => {
    const decoratedOnClick = useAccordionToggle(
        eventKey,
        () => callback && callback(eventKey),
    );

    return (
        <Card.Header onClick={decoratedOnClick}>
            {children}
        </Card.Header>
    );
}

export const ValidateMisureLaterale = (stepsData, currentStepData, currentType) => {
    let isValid = true
    let currentStep = stepsData[currentType].misure_laterale

    if(!currentStep.altezza.h1 || !currentStep.altezza.h2 || !currentStep.altezza.h3 || !currentStep.altezza.h4 || !currentStep.numero_piantoni.numero_piantoni || !currentStep.rinforzi.ra || !currentStep.rinforzi.rp) {
        isValid = false
    }

    for (let i=0; i<5; i++) {
        if (!currentStep.interasse_piantone[i]) {
            isValid = false
        }
    }

    if (stepsData[currentType].teli_laterali.teli_laterali[0]==='Francese' || stepsData[currentType].teli_laterali.teli_laterali[1]==='Scorrevole') {
        if (!currentStep.carrelli.cr1 || !currentStep.carrelli.cr2) {
            isValid = false
        }
    }

    if (stepsData[currentType].teli_laterali.teli_laterali[0]==='Francese') {
        if (!currentStep.cricchetti.passo || !currentStep.cricchetti.distanza_c1 || !currentStep.cricchetti.distanza_c2) {
            isValid = false
        }
    }

    return isValid;
}

export const ValidateMisureLateraleAccordion = (stepsData, currentStepData, currentType) => {
    let isValid = [true, true, true, true, true, true]
    let currentStep = stepsData[currentType].misure_laterale

    if(!currentStep.altezza.h1 || !currentStep.altezza.h2 || !currentStep.altezza.h3 || !currentStep.altezza.h4) {
        isValid[0] = false
    }

    if(!currentStep.numero_piantoni.numero_piantoni) {
        isValid[1] = false
    }

    for (let i=0; i<5; i++) {
        if (!currentStep.interasse_piantone[i]) {
            isValid[2] = false
        }
    }

    if(!currentStep.rinforzi.ra || !currentStep.rinforzi.rp) {
        isValid[3] = false
    }

    if (stepsData[currentType].teli_laterali.teli_laterali[0]==='Francese' || stepsData[currentType].teli_laterali.teli_laterali[1]==='Scorrevole') {
        if (!currentStep.carrelli.cr1 || !currentStep.carrelli.cr2) {
            isValid[4] = false
        }
    }
    else {
        isValid[4] = false
    }

    if (stepsData[currentType].teli_laterali.teli_laterali[0]==='Francese') {
        if (!currentStep.cricchetti.passo || !currentStep.cricchetti.distanza_c1 || !currentStep.cricchetti.distanza_c2) {
            isValid[5] = false
        }
    }
    else {
        isValid[5] = false
    }

    return isValid;
}

export default withRouter(MisureLaterale)