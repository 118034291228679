import React from 'react';
import { withRouter } from "react-router-dom";

import FormRadio from '../Form/Radio';
import FormInput from '../Form/Input.js';

class TipologiaCarrelli extends React.Component {
    constructor(props) {
        super(props);

        this.step = props.steps['tipologia_carrelli'];

        this.state = {
            nextStep: 'tenditelo'
        }

        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount() {
        this.props.updateStep(this.step.id, this.step.name, this.state.nextStep, this.props.match.params.typeId)
        
        if (this.props.currentType) {
            if (this.props.stepsData[this.props.currentType].teli_laterali.teli_laterali[0]==='Francese' && this.props.stepsData[this.props.currentType].tipologia_carrelli.numero_carrelli==='') {
                this.props.updateData(this.props.currentType, this.step.id, 'numero_carrelli', this.props.stepsData[this.props.currentType].cricchetti.numero_cricchetti)
            }
        }
    }

    handleInputChange = event => {
        const target = event.target;
        let value = target.getAttribute('type') === 'checkbox' ? target.checked : target.value;
        let name = target.name;

        if (name.indexOf('[')>0) {
            let newName = name.replace(/\[(\w+)]/g, '')
            let key = /\[(.*?)\]/g.exec(name)[1]

            let newValue = this.props.stepsData[this.props.currentType][this.step.id][newName]
            newValue[key] = value

            name = newName
            value = newValue
        }

        this.props.updateData(this.props.currentType, this.step.id, name, value)
    }

    render() {
        if (this.props.stepsData[this.props.currentType]) {
            return (
                <div>
                    <div className="step step__cricchetti">

                        <div className="row align-items-center justify-content-center bottom-fields">
                            <div className='item-number col-16 col-lg-8 col-xl-5'>
                                <FormInput type="number" name="numero_carrelli" id="numero_carrelli" value={this.props.stepsData[this.props.currentType].tipologia_carrelli.numero_carrelli} label="Numero Carrelli" handleChange={this.handleInputChange} />
                            </div>
                        </div>
                        
                        <div className="scrollable-options">
                            <FormRadio name="tipologia_carrelli" id="tipologia_carrelli1" value="Recupero carrello esistente" label="Recupero carrello esistente" image={true} currentStep={this.props.currentStep} handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].tipologia_carrelli.tipologia_carrelli === 'Recupero carrello esistente'}>
                                <div>
                                    <span className="title">Recupera esistenti</span>
                                    <FormInput type="text" name="tipologia_carrelli_esistenti" id="tipologia_carrelli_esistenti" value={this.props.stepsData[this.props.currentType].tipologia_carrelli.tipologia_carrelli_esistenti} label="Indica tipologia" handleChange={this.handleInputChange} />
                                </div>
                            </FormRadio>
                            <FormRadio name="tipologia_carrelli" id="tipologia_carrelli2" value="Versus T.N." label="Versus T.N." image={true} currentStep={this.props.currentStep} handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].tipologia_carrelli.tipologia_carrelli === 'Versus T.N.'} />
                            <FormRadio name="tipologia_carrelli" id="tipologia_carrelli3" value="Versus T.V." label="Versus T.V." image={true} currentStep={this.props.currentStep} handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].tipologia_carrelli.tipologia_carrelli === 'Versus T.V.'} />
                            <FormRadio name="tipologia_carrelli" id="tipologia_carrelli4" value="EDCHA Asola" label="EDCHA Asola" image={true} currentStep={this.props.currentStep} handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].tipologia_carrelli.tipologia_carrelli === 'EDCHA Asola'} />
                            <FormRadio name="tipologia_carrelli" id="tipologia_carrelli5" value="EDCHA Doppio staffa piegata" label="EDCHA Doppio staffa piegata" image={true} currentStep={this.props.currentStep} handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].tipologia_carrelli.tipologia_carrelli === 'EDCHA Doppio staffa piegata'} />
                            <FormRadio name="tipologia_carrelli" id="tipologia_carrelli6" value="EDCHA staffa dritta" label="EDCHA staffa dritta" image={true} currentStep={this.props.currentStep} handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].tipologia_carrelli.tipologia_carrelli === 'EDCHA staffa dritta'} />
                            <FormRadio name="tipologia_carrelli" id="tipologia_carrelli7" value="EDCHA staffa piegata" label="EDCHA staffa piegata" image={true} currentStep={this.props.currentStep} handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].tipologia_carrelli.tipologia_carrelli === 'EDCHA staffa piegata'} />
                            <FormRadio name="tipologia_carrelli" id="tipologia_carrelli8" value="EDCHA Bigstar dritto" label="EDCHA Bigstar dritto" image={true} currentStep={this.props.currentStep} handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].tipologia_carrelli.tipologia_carrelli === 'EDCHA Bigstar dritto'} />
                            <FormRadio name="tipologia_carrelli" id="tipologia_carrelli9" value="EDCHA Bigstar asola" label="EDCHA Bigstar asola" image={true} currentStep={this.props.currentStep} handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].tipologia_carrelli.tipologia_carrelli === 'EDCHA Bigstar asola'} />
                            <FormRadio name="tipologia_carrelli" id="tipologia_carrelli10" value="EDCHA asola" label="EDCHA asola" image={true} currentStep={this.props.currentStep} handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].tipologia_carrelli.tipologia_carrelli === 'EDCHA asola'} />
                            <FormRadio name="tipologia_carrelli" id="tipologia_carrelli11" value="EDCHA 4 ruote staffa piegata" label="EDCHA 4 ruote staffa piegata" image={true} currentStep={this.props.currentStep} handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].tipologia_carrelli.tipologia_carrelli === 'EDCHA 4 ruote staffa piegata'} />
                            <FormRadio name="tipologia_carrelli" id="tipologia_carrelli12" value="Carrellino 4 ruote" label="Carrellino 4 ruote" image={true} currentStep={this.props.currentStep} handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].tipologia_carrelli.tipologia_carrelli === 'Carrellino 4 ruote'} />
                            <FormRadio name="tipologia_carrelli" id="tipologia_carrelli13" value="Carrellino 2 ruote" label="Carrellino 2 ruote" image={true} currentStep={this.props.currentStep} handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].tipologia_carrelli.tipologia_carrelli === 'Carrellino 2 ruote'} />
                            <FormRadio name="tipologia_carrelli" id="tipologia_carrelli14" value="Carrellino Alustar" label="Carrellino Alustar" image={true} currentStep={this.props.currentStep} handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].tipologia_carrelli.tipologia_carrelli === 'Carrellino Alustar'} />
                            <FormRadio name="tipologia_carrelli" id="tipologia_carrelli15" value="Alustar Piccolo" label="Alustar Piccolo" image={true} currentStep={this.props.currentStep} handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].tipologia_carrelli.tipologia_carrelli === 'Alustar Piccolo'} />
                            <FormRadio name="tipologia_carrelli" id="tipologia_carrelli16" value="Alu Schmall" label="Alu Schmall" image={true} currentStep={this.props.currentStep} handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].tipologia_carrelli.tipologia_carrelli === 'Alu Schmall'} />
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return null
        }
    }
}

export const ValidateTipologiaCarrelli = (stepsData, currentStepData, currentType) => {
    let isValid = true
    let currentStep = stepsData[currentType].tipologia_carrelli

    if(!currentStep.tipologia_carrelli || !currentStep.numero_carrelli) {
        isValid = false
    }
    else if (currentStep.tipologia_carrelli==='Recupero carrello esistente' && !currentStep.tipologia_carrelli_esistenti) {
        isValid = false
    }

    return isValid;
}

export default withRouter(TipologiaCarrelli)