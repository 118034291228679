import React from 'react';
import SimpleStorage from "react-simple-storage";
import { withRouter } from "react-router-dom";

import FormRadio from '../Form/Radio';

class Retroporte extends React.Component {
    constructor(props) {
        super(props);

        this.step = props.steps['retroporte'];

        this.state = {
            nextStep: 'retroporte_a'
        }

        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount() {
        this.props.updateStep(this.step.id, this.step.name, this.state.nextStep, 'other')
    }

    handleInputChange = event => {
        const target = event.target;
        let value = target.getAttribute('type') === 'checkbox' ? target.checked : target.value;
        let name = target.name;

        if (name.indexOf('[')>0) {
            let newName = name.replace(/\[(\w+)]/g, '')
            let key = /\[(.*?)\]/g.exec(name)[1]

            let newValue = this.props.stepsData[this.props.currentType][this.step.id][newName]
            newValue[key] = value

            name = newName
            value = newValue
        }

        this.props.updateData(this.props.currentType, this.step.id, name, value)
        this.updateNextStep(value)
    }

    updateNextStep = (choice) => {
        let nextStep = 'retroporte_a'

        if (choice==='Retro Porte B') {
            nextStep = 'retroporte_b'
        }

        this.setState({ 
            nextStep: nextStep
        }, () => {
            this.props.updateStep(this.step.id, this.step.name, this.state.nextStep);
        })
    }

    render() {
        if (this.props.stepsData) {
            return (
                <div className="step step__retroporte">
                    <SimpleStorage parent={this} prefix={"Configurator"} onParentStateHydrated={() => {
                        this.updateNextStep(this.props.stepsData.retroporte.retroporte)
                    }} />
                    <div className="row justify-content-center">
                        <div className="col-22 col-md-10 col-lg-6 offset-1 offset-lg-2">
                            <FormRadio name="retroporte" id="retroporte1" value="Retro Porte A" label="Retro Porte A" image={true} currentStep={this.props.currentStep} handleChange={this.handleInputChange} checked={this.props.stepsData.retroporte.retroporte === 'Retro Porte A'} />
                        </div>
                        <div className="col-22 col-md-10 col-lg-6 offset-1">
                            <FormRadio name="retroporte" id="retroporte2" value="Retro Porte B" label="Retro Porte B" image={true} currentStep={this.props.currentStep} handleChange={this.handleInputChange} checked={this.props.stepsData.retroporte.retroporte === 'Retro Porte B'} />
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return null
        }
    }
}

export const ValidateRetroporte = (stepsData, currentStepData, currentType) => {
    let isValid = true
    let currentStep = stepsData.retroporte

    if(!currentStep.retroporte) {
        isValid = false
    }

    return isValid;
}

export default withRouter(Retroporte)
