import React from 'react';
import { withRouter } from "react-router-dom";

import FormRadio from '../Form/Radio';

class Tenditelo extends React.Component {
    constructor(props) {
        super(props);

        this.step = props.steps['tenditelo'];

        this.state = {
            nextStep: 'rete_antivandalismo'
        }

        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount() {
        this.props.updateStep(this.step.id, this.step.name, this.state.nextStep, this.props.match.params.typeId)
    }

    handleInputChange = event => {
        const target = event.target;
        let value = target.getAttribute('type') === 'checkbox' ? target.checked : target.value;
        let name = target.name;

        if (name.indexOf('[')>0) {
            let newName = name.replace(/\[(\w+)]/g, '')
            let key = /\[(.*?)\]/g.exec(name)[1]

            let newValue = this.props.stepsData[this.props.currentType][this.step.id][newName]
            newValue[key] = value

            name = newName
            value = newValue
        }

        this.props.updateData(this.props.currentType, this.step.id, name, value)
    }

    render() {
        if (this.props.stepsData[this.props.currentType]) {
            return (
                <div>
                    <div className="step step__cricchetti">
                        <div className="scrollable-options">
                            <FormRadio name="tenditelo" id="tenditelo_1" value="Tubo Tensotel" label="Tubo Tensotel" image={true} currentStep={this.props.currentStep} handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].tenditelo.tenditelo === 'Tubo Tensotel'}>
                                <div>
                                    <span className="title">Tubo Tensotel</span>
                                    <div className="inline">
                                        <FormRadio name="tipologia" id="tipologia1" value="27" label="27" handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].tenditelo.tenditelo === 'Tubo Tensotel' && this.props.stepsData[this.props.currentType].tenditelo.tipologia === '27'} />
                                        <FormRadio name="tipologia" id="tipologia2" value="34" label="34" handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].tenditelo.tenditelo === 'Tubo Tensotel' && this.props.stepsData[this.props.currentType].tenditelo.tipologia === '34'} />
                                    </div>
                                    <FormRadio name="posizione" id="posizione1" value="Anteriore" label="Anteriore" handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].tenditelo.tenditelo === 'Tubo Tensotel' && this.props.stepsData[this.props.currentType].tenditelo.posizione === 'Anteriore'} />
                                    <FormRadio name="posizione" id="posizione2" value="Posteriore" label="Posteriore" handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].tenditelo.tenditelo === 'Tubo Tensotel' && this.props.stepsData[this.props.currentType].tenditelo.posizione === 'Posteriore'} />
                                </div>
                            </FormRadio>
                            <FormRadio name="tenditelo" id="tenditelo_2" value="Tensotel TN" label="Tensotel TN" image={true} currentStep={this.props.currentStep} handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].tenditelo.tenditelo === 'Tensotel TN'}>
                                <div>
                                    <span className="title">Tensotel TN</span>
                                    <FormRadio name="posizione" id="posizione1" value="Anteriore" label="Anteriore" handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].tenditelo.tenditelo === 'Tensotel TN' && this.props.stepsData[this.props.currentType].tenditelo.posizione === 'Anteriore'} />
                                    <FormRadio name="posizione" id="posizione2" value="Posteriore" label="Posteriore" handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].tenditelo.tenditelo === 'Tensotel TN' && this.props.stepsData[this.props.currentType].tenditelo.posizione === 'Posteriore'} />
                                </div>
                            </FormRadio>
                            <FormRadio name="tenditelo" id="tenditelo_3" value="Tensotel TV" label="Tensotel TV" image={true} currentStep={this.props.currentStep} handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].tenditelo.tenditelo === 'Tensotel TV'}>
                                <div>
                                    <span className="title">Tensotel TV</span>
                                    <FormRadio name="posizione" id="posizione1" value="Anteriore" label="Anteriore" handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].tenditelo.tenditelo === 'Tensotel TV' && this.props.stepsData[this.props.currentType].tenditelo.posizione === 'Anteriore'} />
                                    <FormRadio name="posizione" id="posizione2" value="Posteriore" label="Posteriore" handleChange={this.handleInputChange} checked={this.props.stepsData[this.props.currentType].tenditelo.tenditelo === 'Tensotel TV' && this.props.stepsData[this.props.currentType].tenditelo.posizione === 'Posteriore'} />
                                </div>
                            </FormRadio>
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return null
        }
    }
}

export const ValidateTenditelo = (stepsData, currentStepData, currentType) => {
    let isValid = true
    let currentStep = stepsData[currentType].tenditelo

    if(!currentStep.tenditelo || !currentStep.posizione) {
        isValid = false
    }
    else if (currentStep.tenditelo==='Tubo Tensotel' && !currentStep.tipologia) {
        isValid = false
    }

    return isValid;
}

export default withRouter(Tenditelo)