import React from 'react';
import { withRouter } from "react-router-dom";

import Image from './Image.js';
import FormInput from '../Form/Input';

class Traversi extends React.Component {
    constructor(props) {
        super(props);

        this.step = props.steps['traversi'];

        this.state = {
            nextStep: 'correnti'
        }

        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount() {
        this.props.updateStep(this.step.id, this.step.name, this.state.nextStep, this.props.match.params.typeId)
    }

    handleInputChange = event => {
        const target = event.target;
        let value = target.getAttribute('type') === 'checkbox' ? target.checked : target.value;
        let name = target.name;

        if (name.indexOf('[')>0) {
            let newName = name.replace(/\[(\w+)]/g, '')
            let key = /\[(.*?)\]/g.exec(name)[1]

            let newValue = this.props.stepsData[this.props.currentType][this.step.id][newName]
            newValue[key] = value

            name = newName
            value = newValue
        }

        this.props.updateData(this.props.currentType, this.step.id, name, value)
    }

    printImage = () => {
        if (this.props.stepsData[this.props.currentType].tipologia_tetto.tipologia_tetto==='Tetto fisso') {
            return (
                <Image directory="tipologia_tetto" name="tipologia_tetto1" changeType={true} currentType={this.props.stepsData.tipologia_mezzo.tipo_mezzo} />
            )
        }
        else {
            return (
                <Image directory="tipologia_tetto" name="tipologia_tetto2" changeType={true} currentType={this.props.stepsData.tipologia_mezzo.tipo_mezzo} />
            )
        }
    }

    render() {
        if (this.props.stepsData[this.props.currentType]) {
            return (
                <div className="step step__traversi">
                    <div className="row">
                        <div className="col-22 offset-1">
                            <div className="step__image">
                                {this.printImage()}
                            </div>
                        </div>
                        <div className="col-22 col-lg-20 col-xl-18 offset-1 offset-lg-2 offset-xl-3">
                            <div className="step__form">
                                <div className="row justify-content-center">
                                    <div className="col-24 col-lg-10 col-xl-8 offset-lg-0">
                                        <FormInput type="number" name="numero_traversi" id="numero_traversi" value={this.props.stepsData[this.props.currentType].traversi.numero_traversi} label="N° Traversi" handleChange={this.handleInputChange} />
                                        <br/>
                                    </div>
                                    <div className="col-24">
                                        <div className="row justify-content-center align-items-center">
                                            <div className="col-24 col-xl-auto text-md-center text-lg-center">
                                                <span className="form-label">Passo Traversi</span>
                                                <br className="d-none d-md-inline d-lg-inline d-xl-none"/>
                                                <br className="d-none d-md-inline d-lg-inline d-xl-none"/>
                                            </div>
                                            <div className="col-24 col-md-12 col-lg-6 col-xl-4 no-form-margin-desktop">
                                                <FormInput type="number" name="passo_traversi[a]" id="passo_a" value={this.props.stepsData[this.props.currentType].traversi.passo_traversi.a} label="A" handleChange={this.handleInputChange} />
                                            </div>
                                            <div className="col-24 col-md-12 col-lg-6 col-xl-4 no-form-margin-desktop">
                                                <FormInput type="number" name="passo_traversi[b]" id="passo_b" value={this.props.stepsData[this.props.currentType].traversi.passo_traversi.b} label="B" handleChange={this.handleInputChange} />
                                            </div>
                                            <div className="col-24 col-md-12 col-lg-6 col-xl-4 no-form-margin-desktop">
                                                <FormInput type="number" name="passo_traversi[c]" id="passo_c" value={this.props.stepsData[this.props.currentType].traversi.passo_traversi.c} label="C" handleChange={this.handleInputChange} />
                                            </div>
                                            <div className="col-24 col-md-12 col-lg-6 col-xl-4 no-form-margin-desktop">
                                                <FormInput type="number" name="passo_traversi[d]" id="passo_d" value={this.props.stepsData[this.props.currentType].traversi.passo_traversi.d} label="D" handleChange={this.handleInputChange} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return null
        }
    }
}

export const ValidateTraversi = (stepsData, currentStepData, currentType) => {
    let isValid = true
    let currentStep = stepsData[currentType].traversi

    if(!currentStep.numero_traversi || !currentStep.passo_traversi.a || !currentStep.passo_traversi.b || !currentStep.passo_traversi.c || !currentStep.passo_traversi.d) {
        isValid = false
    }

    return isValid;
}

export default withRouter(Traversi)
